import React from 'react';
import { useTranslation } from 'react-i18next';
import {
    Form,
    FORM_FIELDS,
    validationSchema,
} from 'views/eligibility-overrides-navigator/eligibility-overrides-search/components/eligibility-overrides-search-form/validators';
import { useForm } from 'react-hook-form';
import { StyledInput } from 'components/inputs/styled-input';
import styled from 'styled-components';
import { StyledSubmitButton } from 'components/styled-button';
import { MASKS } from 'constants/masks';
import { MaskedInput } from 'components/inputs/masked-input';

const SContainer = styled.form`
    display: flex;
    flex-direction: column;
    margin-bottom: 2rem;
    padding-bottom: 4rem;
    border-bottom: 1px solid ${({ theme }) => theme.COLORS.GRAY};
`;

const SSubContainer = styled.div`
    display: grid;
    grid-template-columns: 30% 30% 30%;
    grid-gap: 5%;
    margin-bottom: 2rem;
    ${({ theme }) => theme.MEDIA.MAX_1024`
        grid-template-columns: 48% 48%;
        grid-gap: 4%;
    `};
    ${({ theme }) => theme.MEDIA.MAX_768`
        grid-template-columns: 100%;
        grid-gap: 0%;
    `};
`;

type Props = {
    onSubmit: (data: Form) => void;
    eligibilityOverridesSearchData: Partial<Form>;
};

export const EligibilityOverridesSearchForm = ({
    eligibilityOverridesSearchData,
    onSubmit,
}: Props) => {
    const { t } = useTranslation();
    const { firstName, lastName, userId, userObjectId, careFirstMemberId } =
        eligibilityOverridesSearchData;

    const { formState, control, handleSubmit } = useForm<Form>({
        defaultValues: {
            [FORM_FIELDS.FIRST_NAME]: firstName || '',
            [FORM_FIELDS.LAST_NAME]: lastName || '',
            [FORM_FIELDS.USER_ID]: userId || '',
            [FORM_FIELDS.USER_OBJECT_ID]: userObjectId || '',
            [FORM_FIELDS.CARE_FIRST_MEMBER_ID]: careFirstMemberId || '',
        },
        resolver: validationSchema,
    });

    return (
        <SContainer onSubmit={handleSubmit(onSubmit)}>
            <SSubContainer>
                <StyledInput
                    name={FORM_FIELDS.FIRST_NAME}
                    control={control}
                    label={t('eligibilityOverridesSearch.firstName')}
                    error={formState.errors.firstName}
                />
                <StyledInput
                    name={FORM_FIELDS.LAST_NAME}
                    control={control}
                    label={t('eligibilityOverridesSearch.lastName')}
                    error={formState.errors.lastName}
                />
                <StyledInput
                    name={FORM_FIELDS.USER_ID}
                    control={control}
                    label={t('eligibilityOverridesSearch.closeKnitUserId')}
                    error={formState.errors.userId}
                />
                <MaskedInput
                    name={FORM_FIELDS.USER_OBJECT_ID}
                    control={control}
                    label={t('eligibilityOverridesSearch.userObjectId')}
                    error={formState.errors.userObjectId}
                    mask={MASKS.GUID}
                />
                <StyledInput
                    name={FORM_FIELDS.CARE_FIRST_MEMBER_ID}
                    control={control}
                    label={t('eligibilityOverridesSearch.careFirstMemberId')}
                    error={formState.errors.careFirstMemberId}
                />
            </SSubContainer>
            <StyledSubmitButton>{t('common.search')}</StyledSubmitButton>
        </SContainer>
    );
};
