import { FieldError } from 'react-hook-form';
import { TFunction } from 'i18next';

type Props = {
    t: TFunction;
    error: FieldError | undefined;
    label?: string;
    charsLength?: number;
};

export const resolveErrorMessage = (props: Props): string | undefined => {
    const { t, error, ...rest } = props;
    const message = error?.message;

    if (!message) {
        return;
    }

    return t(message, { ...rest });
};
