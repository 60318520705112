import React from 'react';
import { MsalProvider } from '@azure/msal-react';
import pca from 'api/auth/msalClient';
import { Theme } from 'theme';
import { Header } from 'components/header';
import { BrowserRouter } from 'react-router-dom';
import { Pages } from 'navigation';
import { i18nInit } from 'locales/i18n';
import { StyledToaster } from 'components/styled-toaster';
import { AuthenticatedContainer } from 'components/authenticated-container';

i18nInit();

export const App = () => {
    return (
        <Theme>
            <StyledToaster />
            <BrowserRouter>
                <MsalProvider instance={pca}>
                    <AuthenticatedContainer>
                        <Header />
                        <Pages />
                    </AuthenticatedContainer>
                </MsalProvider>
            </BrowserRouter>
        </Theme>
    );
};
