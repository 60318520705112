import React, { ReactNode } from 'react';
import styled from 'styled-components';
import { defaultTextStyles } from 'styles/styles';

const SText = styled.p`
    ${defaultTextStyles}
`;

type Props = {
    children: ReactNode;
    className?: string;
};

export const StyledText = ({ children, className }: Props) => {
    return <SText className={className}>{children}</SText>;
};
