import React, { useState } from 'react';
import styled, { css } from 'styled-components';
import { useTranslation } from 'react-i18next';
import { LabelWithValue } from 'components/label-with-value';
import { PaymentStatus, SimpleBillDto } from 'generated';
import dayjs from 'dayjs';
import { StyledText } from 'components/styled-text';
import { Subtitle } from 'components/subtitle';
import { ReactComponent as PlusIcon } from 'assets/svg/ico_plus.svg';
import { ReactComponent as MinusIcon } from 'assets/svg/ico_minus.svg';

const SLi = styled.li`
    padding: 2rem;
    border-radius: 0.5rem;
    background-color: ${({ theme }) => theme.COLORS.WHITE};
    box-shadow: 0px 6px 11px -7px rgba(199, 198, 195, 1);
    margin-bottom: 2rem;
`;

const SRow = styled.div`
    display: flex;
    justify-content: space-between;
`;

const SGrid = styled.div`
    display: grid;
    grid-template-columns: 48% 48%;
    grid-column-gap: 4%;
    ${({ theme }) => theme.MEDIA.MAX_768`
        grid-template-columns: 100%;
        grid-gap: 0%;
        `};
    margin-top: 2rem;
`;

const SUl = styled.ul`
    list-style-type: none;
`;

const SPaymentContainer = styled.div`
    padding: 2rem;
    background-color: ${({ theme }) => theme.COLORS.TAN};
`;

const statusTextStyles = css`
    font-size: ${({ theme }) => theme.FONT.SIZE.MEDIUM};
`;

const SPaidText = styled(StyledText)`
    ${statusTextStyles}
    color: ${({ theme }) => theme.COLORS.SUCCESS_GREEN};
`;

const SDueText = styled(StyledText)`
    ${statusTextStyles}
    color: ${({ theme }) => theme.COLORS.ORANGE};
`;

const SIdText = styled(StyledText)`
    color: ${({ theme }) => theme.COLORS.TAN_THREE};
    margin-right: 2rem;
`;

const SLabelWithValue = styled(LabelWithValue)`
    margin-bottom: 0;
    border-bottom: 0;
`;

const SToggleButton = styled.button`
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 2rem;
    width: 2rem;
`;

type Props = Pick<
    SimpleBillDto,
    | 'id'
    | 'postDate'
    | 'serviceDate'
    | 'allCharges'
    | 'insurancePayment'
    | 'patientOutstanding'
    | 'expectedCharges'
    | 'paymentStatus'
    | 'paymentDate'
    | 'paymentAmount'
    | 'paymentTransaction'
>;

export const BillItem = ({
    id,
    postDate,
    serviceDate,
    allCharges,
    insurancePayment,
    patientOutstanding,
    expectedCharges,
    paymentStatus,
    paymentDate,
    paymentAmount,
    paymentTransaction,
}: Props) => {
    const { t } = useTranslation();
    const [isExpanded, setIsExpanded] = useState(false);

    const isPaid = paymentStatus === PaymentStatus.Success;
    return (
        <SLi>
            <SRow>
                <SRow>
                    <SIdText>
                        {t('userBillings.id')}: {id}
                    </SIdText>
                    {!isExpanded && (
                        <StyledText>
                            {`${t('userBillings.visitTotal')}: $${allCharges?.toFixed(2)}`}
                        </StyledText>
                    )}
                </SRow>
                <SRow>
                    {isPaid ? (
                        <SPaidText>{t('userBillings.paid')}</SPaidText>
                    ) : (
                        <SDueText>{t('userBillings.due')}</SDueText>
                    )}
                    <SToggleButton onClick={() => setIsExpanded(!isExpanded)}>
                        {isExpanded ? <MinusIcon /> : <PlusIcon />}
                    </SToggleButton>
                </SRow>
            </SRow>
            {isExpanded && (
                <SGrid>
                    <SUl>
                        <LabelWithValue
                            label={t('userBillings.visitDate')}
                            value={dayjs(serviceDate).format('MM/DD/YYYY hh:mm')}
                        />
                        <LabelWithValue
                            label={t('userBillings.visitTotal')}
                            value={`$${allCharges?.toFixed(2)}`}
                        />
                        <LabelWithValue
                            label={t('userBillings.amountPaidByInsurance')}
                            value={`$${insurancePayment?.toFixed(2)}`}
                        />
                        <LabelWithValue
                            label={t('userBillings.totalDue')}
                            value={`$${patientOutstanding?.toFixed(2)}`}
                        />
                        <LabelWithValue
                            label={t('userBillings.expectedCharges')}
                            value={`$${expectedCharges?.toFixed(2)}`}
                        />
                        <SLabelWithValue
                            label={t('userBillings.postDate')}
                            value={dayjs(postDate).format('MM/DD/YYYY hh:mm')}
                        />
                    </SUl>
                    <SPaymentContainer>
                        <Subtitle>{t('userBillings.payment')}</Subtitle>
                        {isPaid ? (
                            <SUl>
                                <LabelWithValue
                                    label={t('userBillings.paymentDate')}
                                    value={dayjs(paymentDate).format('MM/DD/YYYY hh:mm')}
                                />
                                <LabelWithValue
                                    label={t('userBillings.paymentAmount')}
                                    value={`$${paymentAmount?.toFixed(2)}`}
                                />
                                <SLabelWithValue
                                    label={t('userBillings.paymentTransaction')}
                                    value={paymentTransaction}
                                />
                            </SUl>
                        ) : (
                            <StyledText>{t('userBillings.noPayment')}</StyledText>
                        )}
                    </SPaymentContainer>
                </SGrid>
            )}
        </SLi>
    );
};
