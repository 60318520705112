export enum ROUTES {
    USERS = 'users',
    USER = '/users/:id',
    USER_DETAILS = '/users/:id/details',
    USER_NOTES = '/users/:id/notes',
    USER_ACTIVITY_LOG = '/users/:id/activity-log',
    USER_DEVICES = '/users/:id/devices',
    USER_BILLINGS = '/users/:id/billings',
    USER_ELIGIBILITY_OVERRIDE = '/users/:id/eligibility-override',
    USER_ELIGIBILITY_OVERRIDE_ADD = '/users/:id/eligibility-override/add',
    USER_ELIGIBILITY_OVERRIDE_EDIT = '/users/:id/eligibility-override/:eligibilityOverrideId',
    ELIGIBILITY_OVERRIDES = 'eligibility-overrides',
    ELIGIBILITY_OVERRIDE_ADD = '/eligibility-overrides/add',
    ELIGIBILITY_OVERRIDE_EDIT = '/eligibility-overrides/:eligibilityOverrideId',
    FEATURE_FLAGS = 'feature-flags',
    FEATURE_FLAGS_APP = '/feature-flags/app',
    FEATURE_FLAGS_BACKEND = '/feature-flags/backend',
}
