/* tslint:disable */
/* eslint-disable */
/**
 * Administration API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface UserActivityLogDto
 */
export interface UserActivityLogDto {
    /**
     * 
     * @type {number}
     * @memberof UserActivityLogDto
     */
    id: number;
    /**
     * 
     * @type {string}
     * @memberof UserActivityLogDto
     */
    userObjectId: string;
    /**
     * 
     * @type {number}
     * @memberof UserActivityLogDto
     */
    userId?: number | null;
    /**
     * 
     * @type {string}
     * @memberof UserActivityLogDto
     */
    name?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UserActivityLogDto
     */
    source?: string | null;
    /**
     * 
     * @type {Date}
     * @memberof UserActivityLogDto
     */
    date: Date;
    /**
     * 
     * @type {string}
     * @memberof UserActivityLogDto
     */
    details?: string | null;
}

export function UserActivityLogDtoFromJSON(json: any): UserActivityLogDto {
    return UserActivityLogDtoFromJSONTyped(json, false);
}

export function UserActivityLogDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): UserActivityLogDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'userObjectId': json['userObjectId'],
        'userId': !exists(json, 'userId') ? undefined : json['userId'],
        'name': !exists(json, 'name') ? undefined : json['name'],
        'source': !exists(json, 'source') ? undefined : json['source'],
        'date': (new Date(json['date'])),
        'details': !exists(json, 'details') ? undefined : json['details'],
    };
}

export function UserActivityLogDtoToJSON(value?: UserActivityLogDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'userObjectId': value.userObjectId,
        'userId': value.userId,
        'name': value.name,
        'source': value.source,
        'date': (value.date.toISOString()),
        'details': value.details,
    };
}

