/* tslint:disable */
/* eslint-disable */
/**
 * Administration API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    FeatureFlagRequest,
    FeatureFlagRequestFromJSON,
    FeatureFlagRequestFromJSONTyped,
    FeatureFlagRequestToJSON,
} from './';

/**
 * 
 * @export
 * @interface UpdateFeatureFlagsAppCommand
 */
export interface UpdateFeatureFlagsAppCommand {
    /**
     * 
     * @type {Array<FeatureFlagRequest>}
     * @memberof UpdateFeatureFlagsAppCommand
     */
    featureFlags: Array<FeatureFlagRequest>;
}

export function UpdateFeatureFlagsAppCommandFromJSON(json: any): UpdateFeatureFlagsAppCommand {
    return UpdateFeatureFlagsAppCommandFromJSONTyped(json, false);
}

export function UpdateFeatureFlagsAppCommandFromJSONTyped(json: any, ignoreDiscriminator: boolean): UpdateFeatureFlagsAppCommand {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'featureFlags': ((json['featureFlags'] as Array<any>).map(FeatureFlagRequestFromJSON)),
    };
}

export function UpdateFeatureFlagsAppCommandToJSON(value?: UpdateFeatureFlagsAppCommand | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'featureFlags': ((value.featureFlags as Array<any>).map(FeatureFlagRequestToJSON)),
    };
}

