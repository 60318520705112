import React, { FC } from 'react';
import { useAccount } from 'api/auth';
import { StyledText } from 'components/styled-text';
import styled from 'styled-components';
import { ReactComponent as LoadingSpinner } from 'assets/svg/ico_loading_spinner.svg';
import { useTranslation } from 'react-i18next';

const SContainer = styled.div`
    height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
`;

const SLoadingSpinner = styled(LoadingSpinner)`
    margin-top: 1rem;
`;

export const AuthenticatedContainer: FC = ({ children }) => {
    const { t } = useTranslation();
    const account = useAccount();
    if (account == null)
        return (
            <SContainer>
                <StyledText>{t('common.loggingIn')}</StyledText>
                <SLoadingSpinner />
            </SContainer>
        );

    return <div>{children}</div>;
};
