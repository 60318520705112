/* tslint:disable */
/* eslint-disable */
/**
 * Administration API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface CreateSimpleBillCommand
 */
export interface CreateSimpleBillCommand {
    /**
     * 
     * @type {number}
     * @memberof CreateSimpleBillCommand
     */
    userId: number;
    /**
     * 
     * @type {Date}
     * @memberof CreateSimpleBillCommand
     */
    serviceDate?: Date | null;
    /**
     * 
     * @type {number}
     * @memberof CreateSimpleBillCommand
     */
    allCharges: number;
    /**
     * 
     * @type {number}
     * @memberof CreateSimpleBillCommand
     */
    insurancePayment: number;
    /**
     * 
     * @type {number}
     * @memberof CreateSimpleBillCommand
     */
    patientOutstanding: number;
    /**
     * 
     * @type {number}
     * @memberof CreateSimpleBillCommand
     */
    expectedCharges: number;
    /**
     * 
     * @type {Date}
     * @memberof CreateSimpleBillCommand
     */
    postDate: Date;
}

export function CreateSimpleBillCommandFromJSON(json: any): CreateSimpleBillCommand {
    return CreateSimpleBillCommandFromJSONTyped(json, false);
}

export function CreateSimpleBillCommandFromJSONTyped(json: any, ignoreDiscriminator: boolean): CreateSimpleBillCommand {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'userId': json['userId'],
        'serviceDate': !exists(json, 'serviceDate') ? undefined : (json['serviceDate'] === null ? null : new Date(json['serviceDate'])),
        'allCharges': json['allCharges'],
        'insurancePayment': json['insurancePayment'],
        'patientOutstanding': json['patientOutstanding'],
        'expectedCharges': json['expectedCharges'],
        'postDate': (new Date(json['postDate'])),
    };
}

export function CreateSimpleBillCommandToJSON(value?: CreateSimpleBillCommand | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'userId': value.userId,
        'serviceDate': value.serviceDate === undefined ? undefined : (value.serviceDate === null ? null : value.serviceDate.toISOString()),
        'allCharges': value.allCharges,
        'insurancePayment': value.insurancePayment,
        'patientOutstanding': value.patientOutstanding,
        'expectedCharges': value.expectedCharges,
        'postDate': (value.postDate.toISOString()),
    };
}

