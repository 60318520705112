import React from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import { ROUTES } from 'constants/routes';
import { CreateEditEligibilityOverride } from 'views/eligibility-overrides-navigator/create-edit-eligibility-override';
import { EligibilityOverridesNavigator } from 'views/eligibility-overrides-navigator';
import { EligibilityOverridesSearch } from 'views/eligibility-overrides-navigator/eligibility-overrides-search';
import { UserNavigator } from 'views/user-navigator';
import { UserSearch } from 'views/user-navigator/user-search';
import { UserDetailsNavigator } from 'views/user-navigator/user-details-navigator';
import { UserNotes } from 'views/user-navigator/user-details-navigator/user-notes';
import { UserDetails } from 'views/user-navigator/user-details-navigator/user-details';
import { UserActivityLog } from 'views/user-navigator/user-details-navigator/user-activity-log';
import { UserDevices } from 'views/user-navigator/user-details-navigator/user-devices';
import { UserBillings } from 'views/user-navigator/user-details-navigator/user-billings';
import { UserEligibilityOverrideNavigator } from 'views/user-navigator/user-details-navigator/user-eligibility-override-navigator';
import { UserEligibilityOverride } from 'views/user-navigator/user-details-navigator/user-eligibility-override-navigator/user-eligibility-override';
import { useScrollToTop } from 'hooks/useScrollToTop';
import { FeatureFlagsNavigator } from 'views/feature-flags-navigator';
import { FeatureFlagsApp } from 'views/feature-flags-navigator/feature-flags-app';
import { FeatureFlagsBackend } from 'views/feature-flags-navigator/feature-flags-backend';
import { ProtectedRoute } from 'components/protected-route';
import { useAccount } from 'api/auth';
import { ROLES } from 'helpers/isAccessibleForUserRoles';

export const Pages = () => {
    useScrollToTop();
    const account = useAccount();
    const userRoles = (account?.idTokenClaims as { roles: ROLES[] }).roles;

    return (
        <Routes>
            <Route path={''} element={<Navigate to={ROUTES.USERS} replace />} />

            <Route path={ROUTES.USERS} element={<UserNavigator />}>
                <Route path={''} element={<UserSearch />} />
                <Route path={ROUTES.USER} element={<UserDetailsNavigator />}>
                    <Route path={''} element={<Navigate to="details" replace />} />
                    <Route path={ROUTES.USER_DETAILS} element={<UserDetails />} />
                    <Route path={ROUTES.USER_NOTES} element={<UserNotes />} />
                    <Route path={ROUTES.USER_ACTIVITY_LOG} element={<UserActivityLog />} />
                    <Route path={ROUTES.USER_DEVICES} element={<UserDevices />} />
                    <Route
                        path={ROUTES.USER_BILLINGS}
                        element={
                            <ProtectedRoute
                                userRoles={userRoles}
                                rolesWithAccess={[ROLES.ADMIN, ROLES.NAVIGATOR]}
                            >
                                <UserBillings />
                            </ProtectedRoute>
                        }
                    />
                    <Route
                        path={ROUTES.USER_ELIGIBILITY_OVERRIDE}
                        element={
                            <ProtectedRoute
                                userRoles={userRoles}
                                rolesWithAccess={[ROLES.ADMIN, ROLES.NAVIGATOR]}
                            >
                                <UserEligibilityOverrideNavigator />
                            </ProtectedRoute>
                        }
                    >
                        <Route path={''} element={<UserEligibilityOverride />} />
                        <Route
                            path={ROUTES.USER_ELIGIBILITY_OVERRIDE_ADD}
                            element={<CreateEditEligibilityOverride />}
                        />
                        <Route
                            path={ROUTES.USER_ELIGIBILITY_OVERRIDE_EDIT}
                            element={<CreateEditEligibilityOverride />}
                        />
                    </Route>
                </Route>
            </Route>
            <Route
                path={ROUTES.ELIGIBILITY_OVERRIDES}
                element={
                    <ProtectedRoute
                        userRoles={userRoles}
                        rolesWithAccess={[ROLES.ADMIN, ROLES.NAVIGATOR]}
                    >
                        <EligibilityOverridesNavigator />
                    </ProtectedRoute>
                }
            >
                <Route path={''} element={<EligibilityOverridesSearch />} />
                <Route
                    path={ROUTES.ELIGIBILITY_OVERRIDE_ADD}
                    element={<CreateEditEligibilityOverride />}
                />
                <Route
                    path={ROUTES.ELIGIBILITY_OVERRIDE_EDIT}
                    element={<CreateEditEligibilityOverride />}
                />
            </Route>

            <Route
                path={ROUTES.FEATURE_FLAGS}
                element={
                    <ProtectedRoute userRoles={userRoles} rolesWithAccess={[ROLES.ADMIN]}>
                        <FeatureFlagsNavigator />
                    </ProtectedRoute>
                }
            >
                <Route path={''} element={<Navigate to={ROUTES.FEATURE_FLAGS_APP} replace />} />
                <Route path={ROUTES.FEATURE_FLAGS_APP} element={<FeatureFlagsApp />} />
                <Route path={ROUTES.FEATURE_FLAGS_BACKEND} element={<FeatureFlagsBackend />} />
            </Route>
        </Routes>
    );
};
