import React, { ReactNode } from 'react';
import { StyledText } from 'components/styled-text';
import styled from 'styled-components';
import { defaultTextStyles, errorTextStyles, visuallyHiddenStyles } from 'styles/styles';

const SContainer = styled.div`
    margin-bottom: 2rem;
`;

const SLabel = styled.label<{ isLabelHidden: boolean }>`
    ${defaultTextStyles}
    margin-bottom: 0.5rem;
    ${({ isLabelHidden }) => isLabelHidden && visuallyHiddenStyles}
`;

const SError = styled(StyledText)`
    ${errorTextStyles}
`;

export type Props = {
    name: string;
    label: string;
    children: ReactNode;
    errorMessage?: string;
    isLabelHidden?: boolean;
};

export const InputContainer = ({
    children,
    name,
    label,
    errorMessage,
    isLabelHidden = false,
}: Props) => {
    return (
        <SContainer>
            <SLabel isLabelHidden={isLabelHidden} htmlFor={name}>
                {label}
            </SLabel>
            {children}
            <SError>{errorMessage}</SError>
        </SContainer>
    );
};
