import React, { MouseEvent, ReactNode } from 'react';
import styled, { css } from 'styled-components';
import { ReactComponent as LoadingSpinner } from 'assets/svg/ico_loading_spinner.svg';

export enum BUTTON_VARIANTS {
    DEFAULT,
    TRANSPARENT,
}

const commonButtonStyles = css`
    font-size: ${({ theme }) => theme.FONT.SIZE.REGULAR};
    font-family: ${({ theme }) => theme.FONT.FAMILY.WORK_SANS_MEDIUM};
    height: 5rem;
    line-height: 2.4rem;
    border-radius: 0.3rem;
    display: flex;
    align-items: center;
    justify-content: center;
    &:disabled {
        background-color: ${({ theme }) => theme.COLORS.GRAY};
        color: ${({ theme }) => theme.COLORS.WHITE};
        pointer-events: none;
    }
`;

const defaultButtonStyles = css`
    background-color: ${({ theme }) => theme.COLORS.PRIMARY};
    color: ${({ theme }) => theme.COLORS.WHITE};
    border: none;
    width: 100%;
    &:hover {
        opacity: 0.9;
    }
`;

const transparentButtonStyles = css`
    color: ${({ theme }) => theme.COLORS.ORANGE};
    border: 1px solid ${({ theme }) => theme.COLORS.ORANGE};
    width: 20rem;
    padding: 1rem 0;
    &:hover {
        background-color: ${({ theme }) => theme.COLORS.ORANGE};
        color: ${({ theme }) => theme.COLORS.WHITE};
    }
    &:disabled {
        border: none;
    }
`;

const SButton = styled.button<{ variant: BUTTON_VARIANTS }>`
    ${commonButtonStyles}
    ${({ variant }) => {
        switch (variant) {
            case BUTTON_VARIANTS.DEFAULT:
                return defaultButtonStyles;
            case BUTTON_VARIANTS.TRANSPARENT:
                return transparentButtonStyles;
        }
    }}
`;

const SLoadingSpinner = styled(LoadingSpinner)`
    stroke: ${({ theme }) => theme.COLORS.WHITE};
    width: 3rem;
    height: 3rem;
`;

type Props = {
    children: ReactNode;
    className?: string;
    onClick: () => void;
    disabled?: boolean;
    isLoading?: boolean;
    variant?: BUTTON_VARIANTS;
};

export const StyledButton = ({
    children,
    onClick,
    className,
    disabled,
    isLoading = false,
    variant = BUTTON_VARIANTS.DEFAULT,
}: Props) => {
    const handleClick = (e: MouseEvent<HTMLButtonElement>) => {
        if (isLoading) return;
        onClick();
        e.stopPropagation();
    };

    return (
        <SButton
            type="button"
            disabled={disabled}
            variant={variant}
            className={className}
            onClick={handleClick}
        >
            {!isLoading ? children : <SLoadingSpinner />}
        </SButton>
    );
};

export const StyledSubmitButton = ({
    children,
    className,
    disabled,
    isLoading = false,
    variant = BUTTON_VARIANTS.DEFAULT,
}: Omit<Props, 'onClick'>) => {
    return (
        <SButton type="submit" disabled={disabled} variant={variant} className={className}>
            {!isLoading ? children : <SLoadingSpinner />}
        </SButton>
    );
};
