import React, { ReactElement, ReactNode } from 'react';
import { ThemeProvider, DefaultTheme, css } from 'styled-components';

type Props = {
    children: ReactNode;
};

export const THEME: DefaultTheme = {
    COLORS: {
        PRIMARY: '#46145b',
        ORANGE: '#F27B40',
        PRIMARY_HOVER: '#330f42',
        WHITE: '#ffffff',
        BLACK_10: 'rgba(0, 0, 0, 0.1)',
        BLACK: '#000000',
        TITLE_BLACK: '#0e1425',
        LINK_BLACK: '#282124',
        ERROR_RED: '#E02020',
        GRAY: '#e2ddd6',
        TAN: '#F9F8F4',
        TAN_50: 'rgba(249,248,244, 0.6)',
        TAN_TWO: '#F1F0EB',
        TAN_THREE: '#C7C6C3',
        SUCCESS_GREEN: '#00cb00',
    },
    BREAKPOINTS: {
        MOBILE: '480px',
        TABLETS: '768px',
        LAPTOP: '1024px',
        DESKTOP: '1200px',
    },
    Z_INDEXES: {
        TEST: 1,
    },
    FONT: {
        SIZE: {
            SMALLEST: '1rem',
            SMALLER: '1.3rem',
            SMALL: '1.4rem',
            REGULAR: '1.6rem',
            MEDIUM: '1.8rem',
            BIG: '2rem',
            BIGGER: '2.4rem',
            TITLE: '4.2rem',
            BIG_TITLE: '6rem',
        },
        WEIGHT: {
            THIN: '100',
            EXTRA_LIGHT: '200',
            LIGHT: '300',
            REGULAR: '400',
            MEDIUM: '500',
            SEMI_BOLD: '600',
            BOLD: '700',
            EXTRA_BOLD: '800',
            HEAVY: '900',
        },
        FAMILY: {
            MACKINAC_MEDIUM: 'Mackinac-Medium',
            WORK_SANS: 'Work-Sans',
            WORK_SANS_MEDIUM: 'Work-Sans-Medium',
        },
    },
    BASE_PADDING: 20,
    MEDIA: {
        MAX_480: (first: TemplateStringsArray, ...args: TemplateStringsArray) => css`
            @media (max-width: 480px) {
                ${css(first, ...args)}
            }
        `,
        MAX_768: (first: TemplateStringsArray, ...args: TemplateStringsArray) => css`
            @media (max-width: 768px) {
                ${css(first, ...args)}
            }
        `,
        MAX_920: (first: TemplateStringsArray, ...args: TemplateStringsArray) => css`
            @media (max-width: 920px) {
                ${css(first, ...args)}
            }
        `,
        MAX_1024: (first: TemplateStringsArray, ...args: TemplateStringsArray) => css`
            @media (max-width: 1024px) {
                ${css(first, ...args)}
            }
        `,
        MAX_1200: (first: TemplateStringsArray, ...args: TemplateStringsArray) => css`
            @media (max-width: 1200px) {
                ${css(first, ...args)}
            }
        `,
    },
};

export const Theme = ({ children }: Props): ReactElement => (
    <ThemeProvider theme={THEME}>{children}</ThemeProvider>
);
