import { TFunction } from 'i18next';
import { ROUTES } from 'constants/routes';
import { isAccessibleForUserRoles, ROLES } from 'helpers/isAccessibleForUserRoles';

export const getNavigationLinks = (t: TFunction, userRoles: ROLES[]) => [
    {
        name: t('header.patientAccounts'),
        route: ROUTES.USERS,
    },
    ...(isAccessibleForUserRoles({ userRoles, rolesWithAccess: [ROLES.ADMIN, ROLES.NAVIGATOR] })
        ? [
              {
                  name: t('header.eligibilityOverrides'),
                  route: ROUTES.ELIGIBILITY_OVERRIDES,
              },
          ]
        : []),
    ...(isAccessibleForUserRoles({ userRoles, rolesWithAccess: [ROLES.ADMIN] })
        ? [
              {
                  name: t('header.featureFlags'),
                  route: ROUTES.FEATURE_FLAGS,
              },
          ]
        : []),
];
