import { Configuration, PublicClientApplication } from '@azure/msal-browser';

export const msalConfig: Configuration = {
    auth: {
        clientId: process.env.REACT_APP_AZURE_AD_CLIENT_ID as string,
        authority: `https://login.microsoftonline.com/${process.env.REACT_APP_AZURE_AD_TENANT_ID}`,
        redirectUri: process.env.REACT_APP_AZURE_AD_REDIRECT_URI as string,
    },
};

const pca = new PublicClientApplication(msalConfig);
pca.enableAccountStorageEvents();

export default pca;
