import React from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { useUserActivityLog } from 'api/swr/hooks/users/useUserActivityLog';
import { Table } from 'components/table';
import { getColumnNames } from 'views/user-navigator/user-details-navigator/user-activity-log/constants';
import dayjs from 'dayjs';
import { useTableState } from 'hooks/useTableState';
import { getPageCount } from 'helpers/getPageCount';

export const UserActivityLog = () => {
    const { setPageNumber, searchData, setSortByList } = useTableState(false);

    const { t } = useTranslation();
    const params = useParams();
    const userId = Number(params.id);

    const { data, isLoading } = useUserActivityLog({ userId, ...searchData });
    const { value } = data || {};

    const pageCount = getPageCount(value?.totalCount, Number(searchData.pageSize));

    const formattedItems = value?.items?.map((item) => {
        return {
            ...item,
            date: dayjs(item.date).format('MM/DD/YYYY hh:mm'),
        };
    });

    return (
        <Table
            columns={getColumnNames(t)}
            data={formattedItems || []}
            isLoading={isLoading}
            controlledPageCount={pageCount}
            setPageNumber={setPageNumber}
            setSortByList={setSortByList}
        />
    );
};
