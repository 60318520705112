/* tslint:disable */
/* eslint-disable */
/**
 * Administration API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface EligibilityOverrideDto
 */
export interface EligibilityOverrideDto {
    /**
     * 
     * @type {number}
     * @memberof EligibilityOverrideDto
     */
    eligibilityOverrideId: number;
    /**
     * 
     * @type {string}
     * @memberof EligibilityOverrideDto
     */
    memberIdEligibilityResult?: string | null;
    /**
     * 
     * @type {string}
     * @memberof EligibilityOverrideDto
     */
    firstName?: string | null;
    /**
     * 
     * @type {string}
     * @memberof EligibilityOverrideDto
     */
    lastName?: string | null;
    /**
     * 
     * @type {number}
     * @memberof EligibilityOverrideDto
     */
    userId?: number | null;
    /**
     * 
     * @type {string}
     * @memberof EligibilityOverrideDto
     */
    userObjectId?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof EligibilityOverrideDto
     */
    active: boolean;
    /**
     * 
     * @type {Date}
     * @memberof EligibilityOverrideDto
     */
    updatedDate?: Date | null;
    /**
     * 
     * @type {string}
     * @memberof EligibilityOverrideDto
     */
    updatedBy?: string | null;
    /**
     * 
     * @type {string}
     * @memberof EligibilityOverrideDto
     */
    comments?: string | null;
}

export function EligibilityOverrideDtoFromJSON(json: any): EligibilityOverrideDto {
    return EligibilityOverrideDtoFromJSONTyped(json, false);
}

export function EligibilityOverrideDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): EligibilityOverrideDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'eligibilityOverrideId': json['eligibilityOverrideId'],
        'memberIdEligibilityResult': !exists(json, 'memberIdEligibilityResult') ? undefined : json['memberIdEligibilityResult'],
        'firstName': !exists(json, 'firstName') ? undefined : json['firstName'],
        'lastName': !exists(json, 'lastName') ? undefined : json['lastName'],
        'userId': !exists(json, 'userId') ? undefined : json['userId'],
        'userObjectId': !exists(json, 'userObjectId') ? undefined : json['userObjectId'],
        'active': json['active'],
        'updatedDate': !exists(json, 'updatedDate') ? undefined : (json['updatedDate'] === null ? null : new Date(json['updatedDate'])),
        'updatedBy': !exists(json, 'updatedBy') ? undefined : json['updatedBy'],
        'comments': !exists(json, 'comments') ? undefined : json['comments'],
    };
}

export function EligibilityOverrideDtoToJSON(value?: EligibilityOverrideDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'eligibilityOverrideId': value.eligibilityOverrideId,
        'memberIdEligibilityResult': value.memberIdEligibilityResult,
        'firstName': value.firstName,
        'lastName': value.lastName,
        'userId': value.userId,
        'userObjectId': value.userObjectId,
        'active': value.active,
        'updatedDate': value.updatedDate === undefined ? undefined : (value.updatedDate === null ? null : value.updatedDate.toISOString()),
        'updatedBy': value.updatedBy,
        'comments': value.comments,
    };
}

