import React from 'react';
import { StyledContainer } from 'components/styled-container';
import { Title } from 'components/title';
import { useTranslation } from 'react-i18next';
import { UserSearchForm } from 'views/user-navigator/user-search/components/user-search-form';
import { UserResults } from 'views/user-navigator/user-search/components/user-results';
import { Form } from 'views/user-navigator/user-search/components/user-search-form/validators';
import queryString from 'query-string';
import { DEFAULT_PAGE_SIZE } from 'helpers/getPageCount';
import { useTableState } from 'hooks/useTableState';

export const UserSearch = () => {
    const { setSearchParams, setPageNumber, setSortByList, resetTableState, searchData } =
        useTableState();

    const { t } = useTranslation();

    const onSubmit = (data: Form) => {
        resetTableState.current = true;
        setSearchParams(
            queryString.stringify(
                { ...data, pageNumber: 0, pageSize: DEFAULT_PAGE_SIZE },
                { skipNull: true, skipEmptyString: true },
            ),
        );
    };

    return (
        <StyledContainer>
            <Title>{t('userSearch.title')}</Title>
            <UserSearchForm onSubmit={onSubmit} userSearchData={searchData} />
            <UserResults
                userSearchData={searchData}
                setPageNumber={setPageNumber}
                resetTableState={resetTableState}
                setSortByList={setSortByList}
            />
        </StyledContainer>
    );
};
