import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';

export enum FORM_FIELDS {
    ACTION = 'action',
}
export type Form = {
    [FORM_FIELDS.ACTION]: string;
};

export const validationSchema = yupResolver(
    yup.object().shape({
        [FORM_FIELDS.ACTION]: yup.string().required(),
    }),
);
