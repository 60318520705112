/* tslint:disable */
/* eslint-disable */
/**
 * Administration API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    FeatureFlagResponse,
    FeatureFlagResponseFromJSON,
    FeatureFlagResponseFromJSONTyped,
    FeatureFlagResponseToJSON,
} from './';

/**
 * 
 * @export
 * @interface FeatureFlagResponseIEnumerableResult
 */
export interface FeatureFlagResponseIEnumerableResult {
    /**
     * 
     * @type {number}
     * @memberof FeatureFlagResponseIEnumerableResult
     */
    readonly statusCode: number;
    /**
     * 
     * @type {string}
     * @memberof FeatureFlagResponseIEnumerableResult
     */
    errorDescription?: string | null;
    /**
     * 
     * @type {Array<FeatureFlagResponse>}
     * @memberof FeatureFlagResponseIEnumerableResult
     */
    value?: Array<FeatureFlagResponse> | null;
}

export function FeatureFlagResponseIEnumerableResultFromJSON(json: any): FeatureFlagResponseIEnumerableResult {
    return FeatureFlagResponseIEnumerableResultFromJSONTyped(json, false);
}

export function FeatureFlagResponseIEnumerableResultFromJSONTyped(json: any, ignoreDiscriminator: boolean): FeatureFlagResponseIEnumerableResult {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'statusCode': json['statusCode'],
        'errorDescription': !exists(json, 'errorDescription') ? undefined : json['errorDescription'],
        'value': !exists(json, 'value') ? undefined : (json['value'] === null ? null : (json['value'] as Array<any>).map(FeatureFlagResponseFromJSON)),
    };
}

export function FeatureFlagResponseIEnumerableResultToJSON(value?: FeatureFlagResponseIEnumerableResult | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'errorDescription': value.errorDescription,
        'value': value.value === undefined ? undefined : (value.value === null ? null : (value.value as Array<any>).map(FeatureFlagResponseToJSON)),
    };
}

