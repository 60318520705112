import { RowData } from 'components/table/types';
import { Column } from 'react-table';

export const getColumnNames = (t: (key: string) => string): Column<RowData>[] => [
    {
        Header: t('userActivityLog.name'),
        accessor: 'name',
    },
    {
        Header: t('userActivityLog.details'),
        accessor: 'details',
    },
    {
        Header: t('userActivityLog.date'),
        accessor: 'date',
    },
    {
        Header: t('userActivityLog.source'),
        accessor: 'source',
    },
];
