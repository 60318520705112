import { TFunction } from 'i18next';

export enum FEATURE_FLAGS {
    APP = 'app',
    BACKEND = 'backend',
}

export const getFeatureFlagsNavigationLinks = (t: TFunction) => [
    {
        name: t('featureFlags.app'),
        route: FEATURE_FLAGS.APP,
    },
    {
        name: t('featureFlags.backend'),
        route: FEATURE_FLAGS.BACKEND,
    },
];
