import React, { memo } from 'react';
import styled, { css } from 'styled-components';
import { defaultTextStyles } from 'styles/styles';
import { RowData } from 'components/table/types';
import { HeaderGroup } from 'react-table';
import isEqual from 'lodash.isequal';

const defaultTrStyles = css`
    padding: 1rem 0;
    display: flex;
`;

const STrHeader = styled.tr`
    ${defaultTrStyles}
    align-items: flex-end;
    border-bottom: 1px solid ${({ theme }) => theme.COLORS.PRIMARY};
`;

const STh = styled.th`
    ${defaultTextStyles}
    overflow-wrap: break-word;
    margin-right: 1rem;
    font-family: ${({ theme }) => theme.FONT.FAMILY.WORK_SANS_MEDIUM};
`;

type Props = {
    headerGroups: HeaderGroup<RowData>[];
};

export const TableHeader = memo(({ headerGroups }: Props) => {
    return (
        <thead>
            {headerGroups.map((headerGroup) => {
                const { key, ...restHeaderGroupProps } = headerGroup.getHeaderGroupProps();
                return (
                    <STrHeader key={key} {...restHeaderGroupProps}>
                        {headerGroup.headers.map((column) => {
                            const { key, ...restColumn } = column.getHeaderProps(
                                column.getSortByToggleProps(),
                            );

                            return (
                                <STh key={key} {...restColumn}>
                                    {column.render('Header')}
                                    {column.isSorted && (column.isSortedDesc ? ' ▼' : ' ▲')}
                                </STh>
                            );
                        })}
                    </STrHeader>
                );
            })}
        </thead>
    );
}, isEqual);
