import React from 'react';
import styled from 'styled-components';
import { StyledText } from 'components/styled-text';
import { useTranslation } from 'react-i18next';
import { PaginationProps } from 'components/table/types';
import { defaultTextStyles } from 'styles/styles';

const SContainer = styled.div`
    margin: 2rem 0;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
`;

const SPaginationButton = styled.button<{ active?: boolean; disabled?: boolean }>`
    ${defaultTextStyles}
    padding: 0 1rem;
    font-family: ${({ active, theme }) => active && theme.FONT.FAMILY.WORK_SANS_MEDIUM};
    font-weight: ${({ active, theme }) => active && theme.FONT.WEIGHT.BOLD};
    color: ${({ active, theme }) => active && theme.COLORS.PRIMARY};
    visibility: ${({ disabled }) => (disabled ? 'hidden' : 'visible')};
`;

export const Pagination = ({
    gotoPage,
    previousPage,
    nextPage,
    canPreviousPage,
    canNextPage,
    pageIndex,
    pageCount,
}: PaginationProps) => {
    const { t } = useTranslation();
    const pageIndexes = [...Array(pageCount).keys()];
    let visiblePageIndexes = pageIndexes;

    if (pageCount > 3) {
        if (pageIndex === 0) {
            visiblePageIndexes = pageIndexes.slice(0, 3);
        } else if (pageIndex === pageCount - 1) {
            visiblePageIndexes = pageIndexes.slice(pageCount - 3, pageCount);
        } else {
            visiblePageIndexes = pageIndexes.slice(pageIndex - 1, pageIndex + 2);
        }
    }

    return (
        <SContainer>
            <StyledText>
                {pageCount ? t('table.page', { page: pageIndex + 1, total: pageCount }) : null}
            </StyledText>
            <div>
                <SPaginationButton onClick={() => gotoPage(0)} disabled={!canPreviousPage}>
                    {'ᐸᐸ'}
                </SPaginationButton>
                <SPaginationButton onClick={() => previousPage()} disabled={!canPreviousPage}>
                    {'ᐸ'}
                </SPaginationButton>
                {!visiblePageIndexes.includes(0) && '...'}
                {visiblePageIndexes.length > 1 &&
                    visiblePageIndexes.map((num) => (
                        <SPaginationButton
                            active={pageIndex === num}
                            key={num}
                            onClick={() => gotoPage(num)}
                        >
                            {String(num + 1)}
                        </SPaginationButton>
                    ))}
                {!visiblePageIndexes.includes(pageCount - 1) && '...'}
                <SPaginationButton onClick={() => nextPage()} disabled={!canNextPage}>
                    {'ᐳ'}
                </SPaginationButton>
                <SPaginationButton onClick={() => gotoPage(pageCount - 1)} disabled={!canNextPage}>
                    {'ᐳᐳ'}
                </SPaginationButton>
            </div>
        </SContainer>
    );
};
