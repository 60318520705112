import React from 'react';
import { Navbar } from 'components/navbar';
import { StyledContainer } from 'components/styled-container';
import { Outlet } from 'react-router-dom';
import { getFeatureFlagsNavigationLinks } from 'views/feature-flags-navigator/constants';
import { useTranslation } from 'react-i18next';

export const FeatureFlagsNavigator = () => {
    const { t } = useTranslation();
    return (
        <>
            <Navbar
                navigationLinks={getFeatureFlagsNavigationLinks}
                title={t('header.featureFlags')}
            />
            <StyledContainer>
                <Outlet />
            </StyledContainer>
        </>
    );
};
