/* tslint:disable */
/* eslint-disable */
/**
 * Administration API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    UserResponse,
    UserResponseFromJSON,
    UserResponseFromJSONTyped,
    UserResponseToJSON,
} from './';

/**
 * 
 * @export
 * @interface UserResponseResult
 */
export interface UserResponseResult {
    /**
     * 
     * @type {number}
     * @memberof UserResponseResult
     */
    readonly statusCode: number;
    /**
     * 
     * @type {string}
     * @memberof UserResponseResult
     */
    errorDescription?: string | null;
    /**
     * 
     * @type {UserResponse}
     * @memberof UserResponseResult
     */
    value?: UserResponse;
}

export function UserResponseResultFromJSON(json: any): UserResponseResult {
    return UserResponseResultFromJSONTyped(json, false);
}

export function UserResponseResultFromJSONTyped(json: any, ignoreDiscriminator: boolean): UserResponseResult {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'statusCode': json['statusCode'],
        'errorDescription': !exists(json, 'errorDescription') ? undefined : json['errorDescription'],
        'value': !exists(json, 'value') ? undefined : UserResponseFromJSON(json['value']),
    };
}

export function UserResponseResultToJSON(value?: UserResponseResult | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'errorDescription': value.errorDescription,
        'value': UserResponseToJSON(value.value),
    };
}

