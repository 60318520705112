import React from 'react';
import styled from 'styled-components';
import { TFunction, useTranslation } from 'react-i18next';
import { NavLink } from 'react-router-dom';
import { Title } from 'components/title';
import { ROLES } from 'helpers/isAccessibleForUserRoles';
import { useAccount } from 'api/auth';

const SContainer = styled.div`
    background-color: ${({ theme }) => theme.COLORS.WHITE};
`;

const SUl = styled.ul`
    display: flex;
    list-style-type: none;
    flex: 1;
`;

const SWrapper = styled.div`
    display: flex;
    flex-direction: column;
    margin: 0 auto;
    max-width: 144rem;
    width: 100%;
    padding: 2rem 2rem;
    ${({ theme }) => theme.MEDIA.MAX_1024`
        width: 100%;
    `};
    overflow-x: auto;
`;

const SNavigationLink = styled(NavLink)`
    color: ${({ theme }) => theme.COLORS.BLACK};
    font-size: ${({ theme }) => theme.FONT.SIZE.REGULAR};
    font-family: ${({ theme }) => theme.FONT.FAMILY.WORK_SANS};
    line-height: 2.4rem;
    padding: 2rem 0;
    margin-right: 3rem;
    white-space: nowrap;
    &.active {
        color: ${({ theme }) => theme.COLORS.PRIMARY};
        font-family: ${({ theme }) => theme.FONT.FAMILY.WORK_SANS_MEDIUM};
        border-bottom: 2px solid ${({ theme }) => theme.COLORS.PRIMARY};
    }
`;

type Props = {
    navigationLinks: (
        t: TFunction,
        userRoles: ROLES[],
    ) => {
        name: string;
        route: string;
    }[];
    title: string;
};

export const Navbar = ({ title, navigationLinks }: Props) => {
    const { t } = useTranslation();
    const account = useAccount();
    const userRoles = (account?.idTokenClaims as { roles: ROLES[] }).roles;

    const navigationLinksToRender = navigationLinks(t, userRoles).map((item) => (
        <li key={item.name}>
            <SNavigationLink to={item.route}>{item.name}</SNavigationLink>
        </li>
    ));

    return (
        <SContainer>
            <SWrapper>
                <Title>{title}</Title>
                <SUl>{navigationLinksToRender}</SUl>
            </SWrapper>
        </SContainer>
    );
};
