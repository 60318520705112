import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { StyledText } from 'components/styled-text';
import { ReactComponent as LoadingSpinner } from 'assets/svg/ico_loading_spinner.svg';
import { useNavigate, useParams } from 'react-router-dom';
import { BUTTON_VARIANTS, StyledButton } from 'components/styled-button';
import { LabelWithValue } from 'components/label-with-value';
import { useEligibilityOverrides } from 'api/swr/hooks/eligibility-overrides/useEligibilityOverrides';

const SRow = styled.div`
    display: flex;
    justify-content: flex-end;
    align-items: flex-start;
    margin-bottom: 2rem;
`;

const SUl = styled.ul`
    display: grid;
    grid-template-columns: 48% 48%;
    list-style-type: none;
    grid-gap: 0 4%;
    margin-bottom: 2rem;
    ${({ theme }) => theme.MEDIA.MAX_1024`
        grid-template-columns: 100%;
        grid-gap: 0%;
    `};
`;

const SCenter = styled.div`
    display: flex;
    justify-content: center;
    margin-top: 3rem;
`;

const NoDataContainer = styled.div`
    justify-content: center;
    align-items: center;
    display: flex;
    height: 15rem;
    width: 100%;
    border-radius: 0.5rem;
    background-color: ${({ theme }) => theme.COLORS.TAN_TWO};
    margin-bottom: 2rem;
`;

export const UserEligibilityOverride = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const params = useParams();
    const userId = Number(params.id);

    const { data, isLoading } = useEligibilityOverrides({
        userId,
    });

    const eligibilityOverridesData = data?.value?.items || [];
    const {
        eligibilityOverrideId,
        memberIdEligibilityResult,
        firstName,
        lastName,
        userObjectId,
        active,
        updatedDate,
        updatedBy,
        comments,
    } = eligibilityOverridesData[0] || {};

    const createEditEligibilityOverride = () => {
        navigate(eligibilityOverrideId ? String(eligibilityOverrideId) : 'add');
    };

    return (
        <>
            <SRow>
                <StyledButton
                    variant={BUTTON_VARIANTS.TRANSPARENT}
                    onClick={createEditEligibilityOverride}
                >
                    {t(
                        `createEditEligibilityOverride.${
                            eligibilityOverrideId
                                ? 'editEligibilityOverride'
                                : 'addEligibilityOverride'
                        }`,
                    )}
                </StyledButton>
            </SRow>
            {isLoading ? (
                <SCenter>
                    <LoadingSpinner />
                </SCenter>
            ) : eligibilityOverrideId ? (
                <SUl>
                    <LabelWithValue
                        label={t('userEligibilityOverride.firstName')}
                        value={firstName}
                    />
                    <LabelWithValue
                        label={t('userEligibilityOverride.lastName')}
                        value={lastName}
                    />
                    <LabelWithValue
                        label={t('userEligibilityOverride.closeKnitUserId')}
                        value={userId}
                    />
                    <LabelWithValue
                        label={t('userEligibilityOverride.userObjectId')}
                        value={userObjectId}
                    />
                    <LabelWithValue
                        label={t('userEligibilityOverride.careFirstMemberId')}
                        value={memberIdEligibilityResult}
                    />
                    <LabelWithValue
                        label={t('userEligibilityOverride.active')}
                        value={t<string>(`common.${active ? 'yes' : 'no'}`)}
                    />
                    <LabelWithValue
                        label={t('userEligibilityOverride.updatedDate')}
                        value={updatedDate?.toLocaleDateString('en-US')}
                    />
                    <LabelWithValue
                        label={t('userEligibilityOverride.updatedBy')}
                        value={updatedBy}
                    />
                    <LabelWithValue
                        label={t('userEligibilityOverride.comments')}
                        value={comments}
                    />
                </SUl>
            ) : (
                <NoDataContainer>
                    <StyledText>{t('userEligibilityOverride.noEligibility')}</StyledText>
                </NoDataContainer>
            )}
        </>
    );
};
