import React from 'react';
import { Control, FieldError, useController, Path } from 'react-hook-form';
import { resolveErrorMessage } from 'helpers/resolveErrorMessage';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import CurrencyInput, { CurrencyInputProps } from 'react-currency-input-field';
import { defaultInputStyles } from 'styles/styles';
import { InputContainer } from 'components/inputs/input-container';

const SCurrencyInput = styled(CurrencyInput)<{ hasError: boolean }>`
    ${defaultInputStyles}
`;

export type Props<T> = {
    name: Path<T>;
    control: Control<T>;
    label: string;
    error?: FieldError;
    className?: string;
} & Pick<CurrencyInputProps, 'prefix' | 'placeholder' | 'disabled'>;

export const StyledCurrencyInput = <T extends Record<string, unknown>>({
    name,
    control,
    label,
    error,
    disabled,
    placeholder,
    className,
    prefix,
}: Props<T>) => {
    const { t } = useTranslation();

    const errorMessage = resolveErrorMessage({
        t,
        error,
        label,
    })?.replace(/\*/g, '');

    const hasError = !!errorMessage;

    const { field } = useController({
        name,
        control,
    });
    const { value, onChange, onBlur, ref } = field;

    const formattedLabel = label?.toLowerCase().replace(/\*/g, '');
    const formattedPlaceholder = placeholder?.replace(/\*/g, '');

    return (
        <InputContainer label={label} name={name} errorMessage={errorMessage}>
            <SCurrencyInput
                id={name}
                name={name}
                prefix={prefix}
                onValueChange={(value) => onChange(value)}
                hasError={hasError}
                placeholder={
                    formattedPlaceholder || t('common.pleaseEnter', { label: formattedLabel })
                }
                disabled={disabled}
                value={value as string}
                className={className}
                onBlur={onBlur}
                ref={ref}
            />
        </InputContainer>
    );
};
