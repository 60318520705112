import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { StyledText } from 'components/styled-text';
import { ReactComponent as LoadingSpinner } from 'assets/svg/ico_loading_spinner.svg';
import { useParams } from 'react-router-dom';
import { DeviceItem } from 'views/user-navigator/user-details-navigator/user-devices/components/device-item';
import { useUserDevices } from 'api/swr/hooks/users/useUserDevices';
import { useAccount } from 'api/auth';
import { isAccessibleForUserRoles, ROLES } from 'helpers/isAccessibleForUserRoles';

const SUl = styled.ul`
    list-style-type: none;
`;

const SCenter = styled.div`
    display: flex;
    justify-content: center;
    margin-top: 3rem;
`;

const NoDataContainer = styled.div`
    justify-content: center;
    align-items: center;
    display: flex;
    height: 15rem;
    width: 100%;
    border-radius: 0.5rem;
    background-color: ${({ theme }) => theme.COLORS.TAN_TWO};
    margin-bottom: 2rem;
`;

export const UserDevices = () => {
    const { t } = useTranslation();
    const params = useParams();
    const userId = Number(params.id);

    const account = useAccount();
    const userRoles = (account?.idTokenClaims as { roles: ROLES[] }).roles;
    const isFirebaseDeviceIdVisible = isAccessibleForUserRoles({
        userRoles,
        rolesWithAccess: [ROLES.ADMIN],
    });

    const { data, isLoading } = useUserDevices({ userId });
    const userDevicesData = data?.value || [];

    return isLoading ? (
        <SCenter>
            <LoadingSpinner />
        </SCenter>
    ) : userDevicesData.length ? (
        <SUl>
            {userDevicesData.map((item) => (
                <DeviceItem
                    isFirebaseDeviceIdVisible={isFirebaseDeviceIdVisible}
                    key={item.id}
                    {...item}
                />
            ))}
        </SUl>
    ) : (
        <NoDataContainer>
            <StyledText>{t('userDevices.noDevices')}</StyledText>
        </NoDataContainer>
    );
};
