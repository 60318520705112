/* tslint:disable */
/* eslint-disable */
/**
 * Administration API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    FeatureFlagResponseIEnumerableResult,
    FeatureFlagResponseIEnumerableResultFromJSON,
    FeatureFlagResponseIEnumerableResultToJSON,
    ProblemDetails,
    ProblemDetailsFromJSON,
    ProblemDetailsToJSON,
    UpdateFeatureFlagsAppCommand,
    UpdateFeatureFlagsAppCommandFromJSON,
    UpdateFeatureFlagsAppCommandToJSON,
    UpdateFeatureFlagsBackendCommand,
    UpdateFeatureFlagsBackendCommandFromJSON,
    UpdateFeatureFlagsBackendCommandToJSON,
} from '../models';

export interface ApiV1FeatureFlagsAppPutRequest {
    updateFeatureFlagsAppCommand?: UpdateFeatureFlagsAppCommand;
}

export interface ApiV1FeatureFlagsBackendPutRequest {
    updateFeatureFlagsBackendCommand?: UpdateFeatureFlagsBackendCommand;
}

/**
 * 
 */
export class FeatureFlagsApi extends runtime.BaseAPI {

    /**
     */
    async apiV1FeatureFlagsAppGetRaw(initOverrides?: RequestInit): Promise<runtime.ApiResponse<FeatureFlagResponseIEnumerableResult>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", []);
        }

        const response = await this.request({
            path: `/api/v1/feature-flags/app`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => FeatureFlagResponseIEnumerableResultFromJSON(jsonValue));
    }

    /**
     */
    async apiV1FeatureFlagsAppGet(initOverrides?: RequestInit): Promise<FeatureFlagResponseIEnumerableResult> {
        const response = await this.apiV1FeatureFlagsAppGetRaw(initOverrides);
        return await response.value();
    }

    /**
     */
    async apiV1FeatureFlagsAppPutRaw(requestParameters: ApiV1FeatureFlagsAppPutRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", []);
        }

        const response = await this.request({
            path: `/api/v1/feature-flags/app`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: UpdateFeatureFlagsAppCommandToJSON(requestParameters.updateFeatureFlagsAppCommand),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async apiV1FeatureFlagsAppPut(requestParameters: ApiV1FeatureFlagsAppPutRequest, initOverrides?: RequestInit): Promise<void> {
        await this.apiV1FeatureFlagsAppPutRaw(requestParameters, initOverrides);
    }

    /**
     */
    async apiV1FeatureFlagsBackendGetRaw(initOverrides?: RequestInit): Promise<runtime.ApiResponse<FeatureFlagResponseIEnumerableResult>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", []);
        }

        const response = await this.request({
            path: `/api/v1/feature-flags/backend`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => FeatureFlagResponseIEnumerableResultFromJSON(jsonValue));
    }

    /**
     */
    async apiV1FeatureFlagsBackendGet(initOverrides?: RequestInit): Promise<FeatureFlagResponseIEnumerableResult> {
        const response = await this.apiV1FeatureFlagsBackendGetRaw(initOverrides);
        return await response.value();
    }

    /**
     */
    async apiV1FeatureFlagsBackendPutRaw(requestParameters: ApiV1FeatureFlagsBackendPutRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", []);
        }

        const response = await this.request({
            path: `/api/v1/feature-flags/backend`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: UpdateFeatureFlagsBackendCommandToJSON(requestParameters.updateFeatureFlagsBackendCommand),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async apiV1FeatureFlagsBackendPut(requestParameters: ApiV1FeatureFlagsBackendPutRequest, initOverrides?: RequestInit): Promise<void> {
        await this.apiV1FeatureFlagsBackendPutRaw(requestParameters, initOverrides);
    }

}
