import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
    Form,
    FORM_FIELDS,
    updateValidationSchema,
    createValidationSchema,
} from 'views/eligibility-overrides-navigator/create-edit-eligibility-override/components/create-edit-eligibility-override-form/validators';
import { useForm } from 'react-hook-form';
import { StyledInput } from 'components/inputs/styled-input';
import styled from 'styled-components';
import { StyledSubmitButton } from 'components/styled-button';
import { useNavigate } from 'react-router-dom';
import { useAuthorized } from 'api';
import { useEligibilityOverrideDetails } from 'api/swr/hooks/eligibility-overrides/useEligibilityOverrideDetails';
import { toast } from 'react-hot-toast';
import { Checkbox } from 'components/inputs/checkbox';
import { Textarea } from 'components/inputs/textarea';
import { EligibilityOverrideApi } from 'generated';

const SContainer = styled.form`
    display: flex;
    flex-direction: column;
    margin-bottom: 2rem;
    padding-bottom: 4rem;
    border-bottom: 1px solid ${({ theme }) => theme.COLORS.GRAY};
`;

const SSubContainer = styled.div`
    display: grid;
    grid-template-columns: 30% 30% 30%;
    grid-gap: 5%;
    margin-bottom: 2rem;
    ${({ theme }) => theme.MEDIA.MAX_1024`
        grid-template-columns: 48% 48%;
        grid-gap: 4%;
    `};
    ${({ theme }) => theme.MEDIA.MAX_768`
        grid-template-columns: 100%;
        grid-gap: 0%;
    `};
`;

type Props = {
    eligibilityOverrideId?: number;
    userId?: number;
};

export const CreateEditEligibilityOverrideForm = ({ eligibilityOverrideId, userId }: Props) => {
    const [isLoading, setIsLoading] = useState(false);
    const { t } = useTranslation();
    const navigate = useNavigate();
    const api = useAuthorized(EligibilityOverrideApi);

    const { formState, control, handleSubmit, reset } = useForm<Form>({
        defaultValues: {
            [FORM_FIELDS.USER_ID]: userId || undefined,
            [FORM_FIELDS.MEMBER_ID_ELIGIBILITY_RESULT]: '',
            [FORM_FIELDS.ACTIVE]: true,
            [FORM_FIELDS.COMMENTS]: '',
        },
        resolver: eligibilityOverrideId ? updateValidationSchema : createValidationSchema,
    });

    const { data } = useEligibilityOverrideDetails({
        eligibilityOverrideId,
    });

    useEffect(() => {
        if (data && data.value) {
            const { memberIdEligibilityResult, active, comments } = data.value;
            reset({
                [FORM_FIELDS.MEMBER_ID_ELIGIBILITY_RESULT]: memberIdEligibilityResult || '',
                [FORM_FIELDS.ACTIVE]: active,
                [FORM_FIELDS.COMMENTS]: comments || '',
            });
        }
    }, [data]);

    const onSubmit = async (data: Form) => {
        const { memberIdEligibilityResult, active, comments, userId } = data;
        setIsLoading(true);
        try {
            if (eligibilityOverrideId) {
                await api.apiV1EligibilityOverridePatch({
                    updateEligibilityOverrideCommand: {
                        memberIdEligibilityResult,
                        active,
                        comments,
                        eligibilityOverrideId,
                    },
                });
            } else {
                await api.apiV1EligibilityOverridePost({
                    createEligibilityOverrideCommand: {
                        userId,
                        memberIdEligibilityResult,
                        active,
                        comments,
                    },
                });
            }
            toast.success(t('createEditEligibilityOverride.eligibilityOverrideSaved'));
        } catch (error) {
            toast.error(t('errors.errorAlert'));
        } finally {
            setIsLoading(false);
            navigate(-1);
        }
    };

    return (
        <SContainer onSubmit={handleSubmit(onSubmit)}>
            <SSubContainer>
                {!eligibilityOverrideId && (
                    <StyledInput
                        name={FORM_FIELDS.USER_ID}
                        control={control}
                        label={t('createEditEligibilityOverride.closeKnitUserId')}
                        error={formState.errors.userId}
                        disabled={!!userId}
                    />
                )}
                <StyledInput
                    name={FORM_FIELDS.MEMBER_ID_ELIGIBILITY_RESULT}
                    control={control}
                    label={t('createEditEligibilityOverride.careFirstMemberId')}
                    error={formState.errors.memberIdEligibilityResult}
                />
                <Textarea
                    rows={4}
                    name={FORM_FIELDS.COMMENTS}
                    control={control}
                    label={t('createEditEligibilityOverride.comments')}
                    error={formState.errors.comments}
                />
                <Checkbox
                    name={FORM_FIELDS.ACTIVE}
                    control={control}
                    label={t('createEditEligibilityOverride.active')}
                    error={formState.errors.active}
                />
            </SSubContainer>
            <StyledSubmitButton isLoading={isLoading}>{t('common.save')}</StyledSubmitButton>
        </SContainer>
    );
};
