import React, { ReactNode, ReactElement } from 'react';

type Props = {
    children: ReactNode;
    isVisible: boolean;
    component: ReactElement;
};

export const PaginationContainer = ({ children, isVisible, component }: Props) => {
    const paginationComponent = !isVisible ? null : component;
    return (
        <>
            {paginationComponent}
            {children}
            {paginationComponent}
        </>
    );
};
