import React from 'react';
import { Control, FieldError, useController, Path } from 'react-hook-form';
import { resolveErrorMessage } from 'helpers/resolveErrorMessage';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { defaultInputStyles } from 'styles/styles';
import { InputContainer } from 'components/inputs/input-container';

const STextareaInput = styled.textarea<{ hasError: boolean }>`
    ${defaultInputStyles}
`;

export type Props<T> = {
    name: Path<T>;
    control: Control<T>;
    label: string;
    rows: number;
    error?: FieldError;
    className?: string;
    isLabelHidden?: boolean;
} & Partial<Pick<HTMLTextAreaElement, 'placeholder' | 'disabled'>>;

export const Textarea = <T extends Record<string, unknown>>({
    name,
    control,
    label,
    error,
    disabled,
    placeholder,
    rows,
    className,
    isLabelHidden = false,
}: Props<T>) => {
    const { t } = useTranslation();

    const errorMessage = resolveErrorMessage({
        t,
        error,
        label,
    })?.replace(/\*/g, '');

    const hasError = !!errorMessage;

    const { field } = useController({
        name,
        control,
    });
    const { value, onChange, onBlur, ref } = field;

    const formattedLabel = label?.toLowerCase().replace(/\*/g, '');
    const formattedPlaceholder = placeholder?.replace(/\*/g, '');

    return (
        <InputContainer
            name={name}
            label={label}
            errorMessage={errorMessage}
            isLabelHidden={isLabelHidden}
        >
            <STextareaInput
                id={name}
                name={name}
                rows={rows}
                hasError={hasError}
                placeholder={
                    formattedPlaceholder || t('common.pleaseEnter', { label: formattedLabel })
                }
                disabled={disabled}
                value={value as string}
                className={className}
                onChange={onChange}
                onBlur={onBlur}
                ref={ref}
            />
        </InputContainer>
    );
};
