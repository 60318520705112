/* tslint:disable */
/* eslint-disable */
/**
 * Administration API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface UserCsSwatNoteResponse
 */
export interface UserCsSwatNoteResponse {
    /**
     * 
     * @type {string}
     * @memberof UserCsSwatNoteResponse
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof UserCsSwatNoteResponse
     */
    note?: string | null;
    /**
     * 
     * @type {Date}
     * @memberof UserCsSwatNoteResponse
     */
    createdUtcDate?: Date | null;
    /**
     * 
     * @type {Date}
     * @memberof UserCsSwatNoteResponse
     */
    updatedUtcDate?: Date | null;
    /**
     * 
     * @type {string}
     * @memberof UserCsSwatNoteResponse
     */
    createdBy?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UserCsSwatNoteResponse
     */
    updatedBy?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UserCsSwatNoteResponse
     */
    userId: string;
}

export function UserCsSwatNoteResponseFromJSON(json: any): UserCsSwatNoteResponse {
    return UserCsSwatNoteResponseFromJSONTyped(json, false);
}

export function UserCsSwatNoteResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): UserCsSwatNoteResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'note': !exists(json, 'note') ? undefined : json['note'],
        'createdUtcDate': !exists(json, 'createdUtcDate') ? undefined : (json['createdUtcDate'] === null ? null : new Date(json['createdUtcDate'])),
        'updatedUtcDate': !exists(json, 'updatedUtcDate') ? undefined : (json['updatedUtcDate'] === null ? null : new Date(json['updatedUtcDate'])),
        'createdBy': !exists(json, 'createdBy') ? undefined : json['createdBy'],
        'updatedBy': !exists(json, 'updatedBy') ? undefined : json['updatedBy'],
        'userId': json['userId'],
    };
}

export function UserCsSwatNoteResponseToJSON(value?: UserCsSwatNoteResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'note': value.note,
        'createdUtcDate': value.createdUtcDate === undefined ? undefined : (value.createdUtcDate === null ? null : value.createdUtcDate.toISOString()),
        'updatedUtcDate': value.updatedUtcDate === undefined ? undefined : (value.updatedUtcDate === null ? null : value.updatedUtcDate.toISOString()),
        'createdBy': value.createdBy,
        'updatedBy': value.updatedBy,
        'userId': value.userId,
    };
}

