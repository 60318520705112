import React, { useState } from 'react';
import { StyledInput } from 'components/inputs/styled-input';
import styled from 'styled-components';
import { useForm } from 'react-hook-form';
import {
    Form,
    FORM_FIELDS,
    validationSchema,
} from 'views/user-navigator/user-details-navigator/user-billings/components/create-bill-form/validators';

import { useTranslation } from 'react-i18next';
import { StyledSubmitButton } from 'components/styled-button';
import toast from 'react-hot-toast';
import { useSimpleBills } from 'api/swr/hooks/simple-bills/useSimpleBills';
import { Title } from 'components/title';
import { StyledCurrencyInput } from 'components/inputs/styled-currency-input';
import { useAuthorized } from 'api';
import { SimpleBillsApi } from 'generated';

const SContainer = styled.form`
    margin-bottom: 6rem;
`;

const SSubContainer = styled.div`
    display: grid;
    grid-template-columns: 30% 30% 30%;
    grid-gap: 5%;
    margin-bottom: 2rem;
    ${({ theme }) => theme.MEDIA.MAX_1024`
        grid-template-columns: 48% 48%;
        grid-gap: 4%;
    `};
    ${({ theme }) => theme.MEDIA.MAX_768`
        grid-template-columns: 100%;
        grid-gap: 0%;
    `};
`;

type Props = {
    userId: number;
    setIsVisibleCreateBill: (isVisible: boolean) => void;
};

export const CreateBillForm = ({ userId, setIsVisibleCreateBill }: Props) => {
    const [isSubmitLoading, setIsSubmitLoading] = useState(false);
    const { mutate } = useSimpleBills({ userId });

    const { t } = useTranslation();

    const { formState, control, handleSubmit } = useForm<Form>({
        resolver: validationSchema,
    });

    const api = useAuthorized(SimpleBillsApi);

    const onSubmit = async (data: Form) => {
        const {
            serviceDate,
            allCharges,
            insurancePayment,
            patientOutstanding,
            expectedCharges,
            postDate,
        } = data;
        try {
            setIsSubmitLoading(true);
            await api.apiV1SimpleBillsPost({
                createSimpleBillCommand: {
                    userId,
                    serviceDate,
                    allCharges,
                    insurancePayment,
                    patientOutstanding,
                    expectedCharges,
                    postDate,
                },
            });
            await mutate();
            toast.success(t('userBillings.successfullySaved'));
            setIsVisibleCreateBill(false);
        } catch (error) {
            toast.error(t('errors.errorAlert'));
        } finally {
            setIsSubmitLoading(false);
        }
    };

    return (
        <SContainer onSubmit={handleSubmit(onSubmit)}>
            <Title>{t('userBillings.addNewBill')}</Title>
            <SSubContainer>
                <StyledInput
                    name={FORM_FIELDS.SERVICE_DATE}
                    control={control}
                    label={t('userBillings.serviceDate')}
                    error={formState.errors.serviceDate}
                    type="datetime-local"
                />
                <StyledInput
                    name={FORM_FIELDS.POST_DATE}
                    control={control}
                    label={t('userBillings.postDate')}
                    error={formState.errors.postDate}
                    type="datetime-local"
                />
                <StyledCurrencyInput
                    name={FORM_FIELDS.ALL_CHARGES}
                    control={control}
                    label={t('userBillings.allCharges')}
                    error={formState.errors.allCharges}
                    prefix="$"
                />
                <StyledCurrencyInput
                    name={FORM_FIELDS.INSURANCE_PAYMENT}
                    control={control}
                    label={t('userBillings.insurancePayment')}
                    error={formState.errors.insurancePayment}
                    prefix="$"
                />
                <StyledCurrencyInput
                    name={FORM_FIELDS.PATIENT_OUTSTANDING}
                    control={control}
                    label={t('userBillings.patientOutstanding')}
                    error={formState.errors.patientOutstanding}
                    prefix="$"
                />
                <StyledCurrencyInput
                    name={FORM_FIELDS.EXPECTED_CHARGES}
                    control={control}
                    label={t('userBillings.expectedCharges')}
                    error={formState.errors.expectedCharges}
                    prefix="$"
                />
            </SSubContainer>
            <StyledSubmitButton isLoading={isSubmitLoading}>{t('common.save')}</StyledSubmitButton>
        </SContainer>
    );
};
