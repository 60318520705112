import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';

export enum FORM_FIELDS {
    FIRST_NAME = 'firstName',
    LAST_NAME = 'lastName',
    USER_ID = 'userId',
    USER_OBJECT_ID = 'userObjectId',
    CARE_FIRST_MEMBER_ID = 'careFirstMemberId',
}

export type Form = {
    [FORM_FIELDS.FIRST_NAME]: string;
    [FORM_FIELDS.LAST_NAME]: string;
    [FORM_FIELDS.USER_ID]: string;
    [FORM_FIELDS.USER_OBJECT_ID]: string;
    [FORM_FIELDS.CARE_FIRST_MEMBER_ID]: string;
};

export const validationSchema = yupResolver(
    yup.object().shape({
        [FORM_FIELDS.FIRST_NAME]: yup.string().trim(),
        [FORM_FIELDS.LAST_NAME]: yup.string().trim(),
        [FORM_FIELDS.USER_ID]: yup
            .string()
            .trim()
            .matches(/^[0-9]+$/, {
                excludeEmptyString: true,
                message: 'errors.fieldMustBeANumber',
            }),
        [FORM_FIELDS.USER_OBJECT_ID]: yup
            .string()
            .trim()
            .matches(/.{36,}/, {
                excludeEmptyString: true,
                message: 'errors.fieldMustContain',
            }),
        [FORM_FIELDS.CARE_FIRST_MEMBER_ID]: yup.string().trim(),
    }),
);
