import { Configuration } from 'generated';
import { useAuthentication, createAuthHeader, AUTH_REQUEST } from 'api/auth';
import { BaseAPI } from 'generated/runtime';
import { useMsal } from '@azure/msal-react';
import { isTokenExpired } from 'helpers/jwtHelpers';
import { msalConfig } from './auth/msalClient';

export function useAuthorized<T extends BaseAPI>(
    ApiClass: new (configuration: Configuration) => T,
) {
    const { instance } = useMsal();
    const authentication = useAuthentication();

    return new ApiClass(
        new Configuration({
            basePath: process.env.REACT_APP_ADMIN_API_BASE_URL,
            accessToken: async () => {
                if (!isTokenExpired(authentication?.accessToken as string)) {
                    return createAuthHeader(authentication);
                }
                try {
                    const newAuthentication = await instance.acquireTokenSilent({
                        account: authentication?.account || undefined,
                        authority: msalConfig.auth.authority!,
                        scopes: AUTH_REQUEST.scopes,
                    });
                    return createAuthHeader(newAuthentication);
                } catch (e) {
                    await instance.logout();
                    return '';
                }
            },
        }),
    );
}
