import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';

export enum FORM_FIELDS {
    USER_ID = 'userId',
    MEMBER_ID_ELIGIBILITY_RESULT = 'memberIdEligibilityResult',
    ACTIVE = 'active',
    COMMENTS = 'comments',
}
export type Form = {
    [FORM_FIELDS.USER_ID]: number;
    [FORM_FIELDS.MEMBER_ID_ELIGIBILITY_RESULT]: string;
    [FORM_FIELDS.ACTIVE]: boolean;
    [FORM_FIELDS.COMMENTS]: string;
};

export const updateValidationSchema = yupResolver(
    yup.object().shape({
        [FORM_FIELDS.MEMBER_ID_ELIGIBILITY_RESULT]: yup.string().trim(),
        [FORM_FIELDS.ACTIVE]: yup.bool(),
        [FORM_FIELDS.COMMENTS]: yup.string().trim(),
    }),
);

export const createValidationSchema = yupResolver(
    yup.object().shape({
        [FORM_FIELDS.USER_ID]: yup
            .number()
            .typeError('errors.fieldMustBeANumber')
            .required('errors.fieldIsRequired'),
        [FORM_FIELDS.MEMBER_ID_ELIGIBILITY_RESULT]: yup
            .string()
            .trim()
            .required('errors.fieldIsRequired'),
        [FORM_FIELDS.ACTIVE]: yup.bool().required('errors.fieldIsRequired'),
        [FORM_FIELDS.COMMENTS]: yup.string().trim().required('errors.fieldIsRequired'),
    }),
);
