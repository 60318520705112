/* tslint:disable */
/* eslint-disable */
/**
 * Administration API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    ProblemDetails,
    ProblemDetailsFromJSON,
    ProblemDetailsToJSON,
    UserDeviceWithFirebaseDeviceIdDtoIEnumerableResult,
    UserDeviceWithFirebaseDeviceIdDtoIEnumerableResultFromJSON,
    UserDeviceWithFirebaseDeviceIdDtoIEnumerableResultToJSON,
} from '../models';

export interface ApiV1UserDeviceGetRequest {
    userId?: number;
}

/**
 * 
 */
export class UserDeviceApi extends runtime.BaseAPI {

    /**
     */
    async apiV1UserDeviceGetRaw(requestParameters: ApiV1UserDeviceGetRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<UserDeviceWithFirebaseDeviceIdDtoIEnumerableResult>> {
        const queryParameters: any = {};

        if (requestParameters.userId !== undefined) {
            queryParameters['UserId'] = requestParameters.userId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", []);
        }

        const response = await this.request({
            path: `/api/v1/user-device`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => UserDeviceWithFirebaseDeviceIdDtoIEnumerableResultFromJSON(jsonValue));
    }

    /**
     */
    async apiV1UserDeviceGet(requestParameters: ApiV1UserDeviceGetRequest, initOverrides?: RequestInit): Promise<UserDeviceWithFirebaseDeviceIdDtoIEnumerableResult> {
        const response = await this.apiV1UserDeviceGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
