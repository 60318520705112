import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';

export enum FORM_FIELDS {
    SERVICE_DATE = 'serviceDate',
    POST_DATE = 'postDate',
    ALL_CHARGES = 'allCharges',
    INSURANCE_PAYMENT = 'insurancePayment',
    PATIENT_OUTSTANDING = 'patientOutstanding',
    EXPECTED_CHARGES = 'expectedCharges',
}
export type Form = {
    [FORM_FIELDS.SERVICE_DATE]: Date;
    [FORM_FIELDS.POST_DATE]: Date;
    [FORM_FIELDS.ALL_CHARGES]: number;
    [FORM_FIELDS.INSURANCE_PAYMENT]: number;
    [FORM_FIELDS.PATIENT_OUTSTANDING]: number;
    [FORM_FIELDS.EXPECTED_CHARGES]: number;
};

export const validationSchema = yupResolver(
    yup.object().shape({
        [FORM_FIELDS.SERVICE_DATE]: yup.date().required('errors.fieldIsRequired'),
        [FORM_FIELDS.POST_DATE]: yup.date().required('errors.fieldIsRequired'),
        [FORM_FIELDS.ALL_CHARGES]: yup
            .number()
            .typeError('errors.fieldMustBeANumber')
            .required('errors.fieldIsRequired'),
        [FORM_FIELDS.INSURANCE_PAYMENT]: yup
            .number()
            .typeError('errors.fieldMustBeANumber')
            .required('errors.fieldIsRequired'),
        [FORM_FIELDS.PATIENT_OUTSTANDING]: yup
            .number()
            .typeError('errors.fieldMustBeANumber')
            .required('errors.fieldIsRequired'),
        [FORM_FIELDS.EXPECTED_CHARGES]: yup
            .number()
            .typeError('errors.fieldMustBeANumber')
            .required('errors.fieldIsRequired'),
    }),
);
