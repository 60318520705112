import React from 'react';
import { Control, FieldError, useController, Path } from 'react-hook-form';
import { resolveErrorMessage } from 'helpers/resolveErrorMessage';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { defaultInputStyles } from 'styles/styles';
import { InputContainer } from 'components/inputs/input-container';

const SInput = styled.input<{ hasError: boolean }>`
    ${defaultInputStyles}
`;

export type Props<T> = {
    name: Path<T>;
    control: Control<T>;
    label: string;
    error?: FieldError;
    className?: string;
} & Partial<Pick<HTMLInputElement, 'type' | 'placeholder' | 'disabled'>>;

export const StyledInput = <T extends Record<string, unknown>>({
    name,
    control,
    label,
    error,
    disabled,
    placeholder,
    type,
    className,
}: Props<T>) => {
    const { t } = useTranslation();

    const errorMessage = resolveErrorMessage({
        t,
        error,
        label,
    })?.replace(/\*/g, '');

    const hasError = !!errorMessage;

    const { field } = useController({
        name,
        control,
    });
    const { value, onChange, onBlur, ref } = field;

    const formattedLabel = label?.toLowerCase().replace(/\*/g, '');
    const formattedPlaceholder = placeholder?.replace(/\*/g, '');

    return (
        <InputContainer label={label} name={name} errorMessage={errorMessage}>
            <SInput
                id={name}
                type={type}
                hasError={hasError}
                placeholder={
                    formattedPlaceholder || t('common.pleaseEnter', { label: formattedLabel })
                }
                disabled={disabled}
                className={className}
                name={name}
                value={value as string}
                onChange={onChange}
                onBlur={onBlur}
                ref={ref}
            />
        </InputContainer>
    );
};
