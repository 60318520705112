import { isAccessibleForUserRoles, ROLES } from 'helpers/isAccessibleForUserRoles';
import { TFunction } from 'i18next';

export enum USER_DETAILS_LINKS {
    DETAILS = 'details',
    NOTES = 'notes',
    ACTIVITY_LOG = 'activity-log',
    DEVICES = 'devices',
    BILLINGS = 'billings',
    ELIGIBILITY_OVERRIDE = 'eligibility-override',
}

export const getUserDetailsNavigationLinks = (t: TFunction, userRoles: ROLES[]) => [
    {
        name: t('userDetails.title'),
        route: USER_DETAILS_LINKS.DETAILS,
    },
    {
        name: t('userNotes.title'),
        route: USER_DETAILS_LINKS.NOTES,
    },
    {
        name: t('userActivityLog.title'),
        route: USER_DETAILS_LINKS.ACTIVITY_LOG,
    },
    {
        name: t('userDevices.title'),
        route: USER_DETAILS_LINKS.DEVICES,
    },
    ...(isAccessibleForUserRoles({ userRoles, rolesWithAccess: [ROLES.ADMIN, ROLES.NAVIGATOR] })
        ? [
              {
                  name: t('userBillings.title'),
                  route: USER_DETAILS_LINKS.BILLINGS,
              },
          ]
        : []),
    ...(isAccessibleForUserRoles({ userRoles, rolesWithAccess: [ROLES.ADMIN, ROLES.NAVIGATOR] })
        ? [
              {
                  name: t('userEligibilityOverride.title'),
                  route: USER_DETAILS_LINKS.ELIGIBILITY_OVERRIDE,
              },
          ]
        : []),
];
