import { RowData } from 'components/table/types';
import { MutableRefObject, useEffect, useRef, useState } from 'react';
import { URLSearchParamsInit, useSearchParams } from 'react-router-dom';
import { SortingRule } from 'react-table';
import queryString from 'query-string';

export const useTableState = (
    isSubmitting = true,
): {
    setPageNumber: (pageNumber: number) => void;
    setSortByList: (sortByList: SortingRule<RowData>[]) => void;
    setSearchParams: (
        nextInit: URLSearchParamsInit,
        navigateOptions?:
            | {
                  replace?: boolean | undefined;
                  state?: any;
              }
            | undefined,
    ) => void;
    resetTableState: MutableRefObject<boolean>;
    searchData: Record<string, unknown>;
} => {
    const [searchParams, setSearchParams] = useSearchParams();
    const [pageNumber, setPageNumber] = useState(0);
    const [sortByList, setSortByList] = useState<SortingRule<RowData>[]>([]);
    const resetTableState = useRef(false);

    const searchData = queryString.parse(searchParams.toString());

    useEffect(() => {
        if (!isSubmitting || searchParams.toString().length) {
            const { id, desc } = sortByList[0] || {};
            const sorterSortType =
                desc === undefined ? undefined : desc ? 'Descending' : 'Ascending';
            setSearchParams(
                queryString.stringify(
                    {
                        ...searchData,
                        pageNumber,
                        sorterKey: id,
                        sorterSortType,
                    },
                    { skipNull: true, skipEmptyString: true },
                ),
            );
        }
    }, [pageNumber, sortByList]);

    return {
        setPageNumber,
        setSortByList,
        setSearchParams,
        searchData,
        resetTableState,
    };
};
