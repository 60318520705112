import React from 'react';
import { StyledContainer } from 'components/styled-container';
import { Outlet } from 'react-router-dom';
import { UserDetailsNavbar } from 'views/user-navigator/user-details-navigator/components/user-details-navbar';

export const UserDetailsNavigator = () => {
    return (
        <>
            <UserDetailsNavbar />
            <StyledContainer>
                <Outlet />
            </StyledContainer>
        </>
    );
};
