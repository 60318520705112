/* tslint:disable */
/* eslint-disable */
/**
 * Administration API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    UserActivityLogDto,
    UserActivityLogDtoFromJSON,
    UserActivityLogDtoFromJSONTyped,
    UserActivityLogDtoToJSON,
} from './';

/**
 * 
 * @export
 * @interface UserActivityLogDtoDataPage
 */
export interface UserActivityLogDtoDataPage {
    /**
     * 
     * @type {Array<UserActivityLogDto>}
     * @memberof UserActivityLogDtoDataPage
     */
    items?: Array<UserActivityLogDto> | null;
    /**
     * 
     * @type {number}
     * @memberof UserActivityLogDtoDataPage
     */
    totalCount: number;
}

export function UserActivityLogDtoDataPageFromJSON(json: any): UserActivityLogDtoDataPage {
    return UserActivityLogDtoDataPageFromJSONTyped(json, false);
}

export function UserActivityLogDtoDataPageFromJSONTyped(json: any, ignoreDiscriminator: boolean): UserActivityLogDtoDataPage {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'items': !exists(json, 'items') ? undefined : (json['items'] === null ? null : (json['items'] as Array<any>).map(UserActivityLogDtoFromJSON)),
        'totalCount': json['totalCount'],
    };
}

export function UserActivityLogDtoDataPageToJSON(value?: UserActivityLogDtoDataPage | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'items': value.items === undefined ? undefined : (value.items === null ? null : (value.items as Array<any>).map(UserActivityLogDtoToJSON)),
        'totalCount': value.totalCount,
    };
}

