/* tslint:disable */
/* eslint-disable */
/**
 * Administration API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface UserDeviceWithFirebaseDeviceIdDto
 */
export interface UserDeviceWithFirebaseDeviceIdDto {
    /**
     * 
     * @type {string}
     * @memberof UserDeviceWithFirebaseDeviceIdDto
     */
    deviceType?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UserDeviceWithFirebaseDeviceIdDto
     */
    appVersion?: string | null;
    /**
     * 
     * @type {Date}
     * @memberof UserDeviceWithFirebaseDeviceIdDto
     */
    lastFirebaseNotificationUtcDate?: Date | null;
    /**
     * 
     * @type {Date}
     * @memberof UserDeviceWithFirebaseDeviceIdDto
     */
    latestLogin?: Date | null;
    /**
     * 
     * @type {number}
     * @memberof UserDeviceWithFirebaseDeviceIdDto
     */
    userId: number;
    /**
     * 
     * @type {number}
     * @memberof UserDeviceWithFirebaseDeviceIdDto
     */
    id: number;
    /**
     * 
     * @type {string}
     * @memberof UserDeviceWithFirebaseDeviceIdDto
     */
    firebaseDeviceId?: string | null;
}

export function UserDeviceWithFirebaseDeviceIdDtoFromJSON(json: any): UserDeviceWithFirebaseDeviceIdDto {
    return UserDeviceWithFirebaseDeviceIdDtoFromJSONTyped(json, false);
}

export function UserDeviceWithFirebaseDeviceIdDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): UserDeviceWithFirebaseDeviceIdDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'deviceType': !exists(json, 'deviceType') ? undefined : json['deviceType'],
        'appVersion': !exists(json, 'appVersion') ? undefined : json['appVersion'],
        'lastFirebaseNotificationUtcDate': !exists(json, 'lastFirebaseNotificationUtcDate') ? undefined : (json['lastFirebaseNotificationUtcDate'] === null ? null : new Date(json['lastFirebaseNotificationUtcDate'])),
        'latestLogin': !exists(json, 'latestLogin') ? undefined : (json['latestLogin'] === null ? null : new Date(json['latestLogin'])),
        'userId': json['userId'],
        'id': json['id'],
        'firebaseDeviceId': !exists(json, 'firebaseDeviceId') ? undefined : json['firebaseDeviceId'],
    };
}

export function UserDeviceWithFirebaseDeviceIdDtoToJSON(value?: UserDeviceWithFirebaseDeviceIdDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'deviceType': value.deviceType,
        'appVersion': value.appVersion,
        'lastFirebaseNotificationUtcDate': value.lastFirebaseNotificationUtcDate === undefined ? undefined : (value.lastFirebaseNotificationUtcDate === null ? null : value.lastFirebaseNotificationUtcDate.toISOString()),
        'latestLogin': value.latestLogin === undefined ? undefined : (value.latestLogin === null ? null : value.latestLogin.toISOString()),
        'userId': value.userId,
        'id': value.id,
        'firebaseDeviceId': value.firebaseDeviceId,
    };
}

