import { RowData } from 'components/table/types';
import { Column } from 'react-table';

export const getColumnNames = (t: (key: string) => string): Column<RowData>[] => [
    {
        Header: t('eligibilityOverridesSearch.firstName'),
        accessor: 'firstName',
    },
    {
        Header: t('eligibilityOverridesSearch.lastName'),
        accessor: 'lastName',
    },
    {
        Header: t('eligibilityOverridesSearch.closeKnitUserId'),
        accessor: 'userId',
        minWidth: 160,
    },
    {
        Header: t('eligibilityOverridesSearch.userObjectId'),
        accessor: 'userObjectId',
    },
    {
        Header: t('eligibilityOverridesSearch.careFirstMemberId'),
        accessor: 'memberIdEligibilityResult',
        minWidth: 180,
    },
    {
        Header: t('eligibilityOverridesSearch.active'),
        accessor: 'active',
    },
    {
        Header: t('eligibilityOverridesSearch.updatedDate'),
        accessor: 'updatedDate',
    },
    {
        Header: t('eligibilityOverridesSearch.updatedBy'),
        accessor: 'updatedBy',
    },
];
