/* tslint:disable */
/* eslint-disable */
/**
 * Administration API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    PaymentStatus,
    PaymentStatusFromJSON,
    PaymentStatusFromJSONTyped,
    PaymentStatusToJSON,
} from './';

/**
 * 
 * @export
 * @interface SimpleBillDto
 */
export interface SimpleBillDto {
    /**
     * 
     * @type {number}
     * @memberof SimpleBillDto
     */
    id: number;
    /**
     * 
     * @type {Date}
     * @memberof SimpleBillDto
     */
    serviceDate?: Date | null;
    /**
     * 
     * @type {string}
     * @memberof SimpleBillDto
     */
    memberId?: string | null;
    /**
     * 
     * @type {Date}
     * @memberof SimpleBillDto
     */
    postDate?: Date | null;
    /**
     * 
     * @type {number}
     * @memberof SimpleBillDto
     */
    allCharges?: number | null;
    /**
     * 
     * @type {number}
     * @memberof SimpleBillDto
     */
    insurancePayment?: number | null;
    /**
     * 
     * @type {number}
     * @memberof SimpleBillDto
     */
    patientOutstanding?: number | null;
    /**
     * 
     * @type {number}
     * @memberof SimpleBillDto
     */
    expectedCharges?: number | null;
    /**
     * 
     * @type {PaymentStatus}
     * @memberof SimpleBillDto
     */
    paymentStatus?: PaymentStatus;
    /**
     * 
     * @type {Date}
     * @memberof SimpleBillDto
     */
    paymentDate?: Date | null;
    /**
     * 
     * @type {number}
     * @memberof SimpleBillDto
     */
    paymentAmount?: number | null;
    /**
     * 
     * @type {string}
     * @memberof SimpleBillDto
     */
    paymentTransaction?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof SimpleBillDto
     */
    seen: boolean;
}

export function SimpleBillDtoFromJSON(json: any): SimpleBillDto {
    return SimpleBillDtoFromJSONTyped(json, false);
}

export function SimpleBillDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): SimpleBillDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'serviceDate': !exists(json, 'serviceDate') ? undefined : (json['serviceDate'] === null ? null : new Date(json['serviceDate'])),
        'memberId': !exists(json, 'memberId') ? undefined : json['memberId'],
        'postDate': !exists(json, 'postDate') ? undefined : (json['postDate'] === null ? null : new Date(json['postDate'])),
        'allCharges': !exists(json, 'allCharges') ? undefined : json['allCharges'],
        'insurancePayment': !exists(json, 'insurancePayment') ? undefined : json['insurancePayment'],
        'patientOutstanding': !exists(json, 'patientOutstanding') ? undefined : json['patientOutstanding'],
        'expectedCharges': !exists(json, 'expectedCharges') ? undefined : json['expectedCharges'],
        'paymentStatus': !exists(json, 'paymentStatus') ? undefined : PaymentStatusFromJSON(json['paymentStatus']),
        'paymentDate': !exists(json, 'paymentDate') ? undefined : (json['paymentDate'] === null ? null : new Date(json['paymentDate'])),
        'paymentAmount': !exists(json, 'paymentAmount') ? undefined : json['paymentAmount'],
        'paymentTransaction': !exists(json, 'paymentTransaction') ? undefined : json['paymentTransaction'],
        'seen': json['seen'],
    };
}

export function SimpleBillDtoToJSON(value?: SimpleBillDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'serviceDate': value.serviceDate === undefined ? undefined : (value.serviceDate === null ? null : value.serviceDate.toISOString()),
        'memberId': value.memberId,
        'postDate': value.postDate === undefined ? undefined : (value.postDate === null ? null : value.postDate.toISOString()),
        'allCharges': value.allCharges,
        'insurancePayment': value.insurancePayment,
        'patientOutstanding': value.patientOutstanding,
        'expectedCharges': value.expectedCharges,
        'paymentStatus': PaymentStatusToJSON(value.paymentStatus),
        'paymentDate': value.paymentDate === undefined ? undefined : (value.paymentDate === null ? null : value.paymentDate.toISOString()),
        'paymentAmount': value.paymentAmount,
        'paymentTransaction': value.paymentTransaction,
        'seen': value.seen,
    };
}

