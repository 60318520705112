import React, { useState } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { StyledButton } from 'components/styled-button';
import { useAuthorized } from 'api';
import toast from 'react-hot-toast';
import { useUser } from 'api/swr/hooks/users/useUser';
import { useParams } from 'react-router-dom';
import { LabelWithValue } from 'components/label-with-value';
import { Subtitle } from 'components/subtitle';
import { EdenHealthApi } from 'generated';
import { isAccessibleForUserRoles, ROLES } from 'helpers/isAccessibleForUserRoles';
import { useAccount } from 'api/auth';

const SCategoryUl = styled.ul`
    display: grid;
    grid-template-columns: 48% 48%;
    list-style-type: none;
    grid-gap: 0 4%;
    margin-bottom: 2rem;
    ${({ theme }) => theme.MEDIA.MAX_1024`
        grid-template-columns: 100%;
        grid-gap: 0%;
    `};
`;

const SUl = styled.ul`
    list-style-type: none;
    margin-bottom: 4rem;
`;

const SButtonsWrapper = styled.div`
    display: flex;
    margin-bottom: 6rem;
`;

const SStyledButton = styled(StyledButton)`
    max-width: 150px;
    margin-right: 2rem;
`;

export const UserDetails = () => {
    const [isEdenSyncLoading, setIsEdenSyncLoading] = useState(false);
    const { t } = useTranslation();
    const api = useAuthorized(EdenHealthApi);
    const params = useParams();
    const userId = Number(params.id);

    const account = useAccount();
    const userRoles = (account?.idTokenClaims as { roles: ROLES[] }).roles;

    const { data } = useUser({ id: userId });

    const {
        firstName,
        lastName,
        dateOfBirth,
        email,
        careFirstMemberId,
        userRelationshipId,
        preferredFirstName,
        gender,
        streetAddress,
        city,
        state,
        zipCode,
        termsOfServiceConsent,
        marketingConsent,
        privacyPolicyConsent,
        phoneNumber,
        streetAddress2,
        edenHealthMemberStatus,
        edenUserAlreadyExists,
        userObjectId,
    } = data?.value || {};

    const onEdenSync = async () => {
        setIsEdenSyncLoading(true);
        try {
            await api.apiV1EdenHealthMemberPut({
                updateEdenMemberCommand: {
                    userId,
                },
            });
            toast.success(t('userDetails.edenSyncSuccessful'));
        } catch (error) {
            toast.error(t('errors.errorAlert'));
        } finally {
            setIsEdenSyncLoading(false);
        }
    };

    return (
        <>
            <SCategoryUl>
                <li>
                    <Subtitle>{t('userDetails.demographics')}</Subtitle>
                    <SUl>
                        <LabelWithValue
                            label={t('userDetails.preferredFirstName')}
                            value={preferredFirstName}
                        />
                        <LabelWithValue label={t('userDetails.firstName')} value={firstName} />
                        <LabelWithValue label={t('userDetails.lastName')} value={lastName} />
                        <LabelWithValue
                            label={t('userDetails.dateOfBirth')}
                            value={dateOfBirth?.toLocaleDateString('en-US')}
                        />
                        <LabelWithValue label={t('userDetails.gender')} value={gender} />
                    </SUl>
                </li>
                <li>
                    <Subtitle>{t('userDetails.contactDetails')}</Subtitle>
                    <SUl>
                        <LabelWithValue label={t('userDetails.email')} value={email} />
                        <LabelWithValue label={t('userDetails.phoneNumber')} value={phoneNumber} />
                        <LabelWithValue
                            label={t('userDetails.streetAddress')}
                            value={streetAddress}
                        />
                        <LabelWithValue
                            label={t('userDetails.streetAddress2')}
                            value={streetAddress2}
                        />
                        <LabelWithValue label={t('userDetails.city')} value={city} />
                        <LabelWithValue label={t('userDetails.state')} value={state} />
                        <LabelWithValue label={t('userDetails.zipCode')} value={zipCode} />
                    </SUl>
                </li>
                <li>
                    <Subtitle>{t('userDetails.identifiers')}</Subtitle>
                    <SUl>
                        <LabelWithValue label={t('userDetails.userId')} value={userId} />
                        <LabelWithValue label={t('userDetails.phoneNumber')} value={phoneNumber} />
                        <LabelWithValue
                            label={t('userDetails.userObjectId')}
                            value={userObjectId}
                        />
                        <LabelWithValue
                            label={t('userDetails.careFirstMemberId')}
                            value={careFirstMemberId}
                        />
                        <LabelWithValue
                            label={t('userDetails.userRelationshipId')}
                            value={userRelationshipId}
                        />
                    </SUl>
                </li>
                <li>
                    <Subtitle>{t('userDetails.status')}</Subtitle>
                    <SUl>
                        <LabelWithValue
                            label={t('userDetails.edenHealthMemberStatus')}
                            value={String(edenHealthMemberStatus)}
                        />
                        <LabelWithValue
                            label={t('userDetails.edenUserAlreadyExists')}
                            value={String(edenUserAlreadyExists)}
                        />
                    </SUl>
                </li>
                <li>
                    <Subtitle>{t('userDetails.consents')}</Subtitle>
                    <SUl>
                        <LabelWithValue
                            label={t('userDetails.termsOfServiceConsent')}
                            value={String(termsOfServiceConsent)}
                        />
                        <LabelWithValue
                            label={t('userDetails.marketingConsent')}
                            value={String(marketingConsent)}
                        />
                        <LabelWithValue
                            label={t('userDetails.privacyPolicyConsent')}
                            value={String(privacyPolicyConsent)}
                        />
                    </SUl>
                </li>
            </SCategoryUl>
            {isAccessibleForUserRoles({ userRoles, rolesWithAccess: [ROLES.ADMIN] }) && (
                <SButtonsWrapper>
                    <SStyledButton isLoading={isEdenSyncLoading} onClick={onEdenSync}>
                        {t('userDetails.edenSync')}
                    </SStyledButton>
                </SButtonsWrapper>
            )}
        </>
    );
};
