import {
    AccountInfo,
    AuthenticationResult,
    EventType,
    InteractionStatus,
    IPublicClientApplication,
} from '@azure/msal-browser';
import { useMsal } from '@azure/msal-react';
import { useCallback, useEffect, useState } from 'react';
import { EventMessage } from '@azure/msal-browser/dist/event/EventMessage';
import { msalConfig } from 'api/auth/msalClient';

type RefreshAuthorizationHeaderProps = {
    instance: IPublicClientApplication;
    account: AccountInfo;
};

export const PREFERRED_DOMAIN = 'healthworxcf.onmicrosoft.com';

export const AUTH_REQUEST = {
    scopes: [`api://${process.env.REACT_APP_AZURE_AD_CLIENT_ID}/access_as_user`],
    domainHint: PREFERRED_DOMAIN,
};

const cache = {
    authentication: null as AuthenticationResult | null,
};

export function useAuthentication(): AuthenticationResult | null {
    const { instance, inProgress, accounts } = useMsal();

    if (accounts.length) {
        instance.setActiveAccount(accounts[0]);
    }

    const login = useCallback(() => {
        instance.loginRedirect(AUTH_REQUEST).then(null);
    }, [instance]);

    const [result, setResult] = useState<AuthenticationResult | null>(cache.authentication);

    useEffect(() => {
        const callbackId = instance.addEventCallback((message: EventMessage) => {
            switch (message.eventType) {
                case EventType.LOGIN_SUCCESS:
                case EventType.SSO_SILENT_SUCCESS:
                case EventType.ACQUIRE_TOKEN_SUCCESS: {
                    cache.authentication = message.payload as AuthenticationResult;
                    setResult(cache.authentication);
                    break;
                }
                case EventType.LOGIN_FAILURE:
                case EventType.SSO_SILENT_FAILURE:
                case EventType.ACQUIRE_TOKEN_FAILURE: {
                    cache.authentication = null;
                    setResult(cache.authentication);
                    break;
                }
            }
        });
        return () => {
            if (callbackId) instance.removeEventCallback(callbackId);
        };
    }, [instance]);

    useEffect(() => {
        if (result == null && inProgress === InteractionStatus.None) {
            login();
        }
    });

    return result;
}

export function useAccount(): AccountInfo | null {
    const authentication = useAuthentication();
    return authentication?.account || null;
}

export function createAuthHeader(authentication: AuthenticationResult | null) {
    return authentication ? authentication.tokenType + ' ' + authentication.accessToken : '';
}

export async function refreshAuthorizationHeader({
    instance,
    account,
}: RefreshAuthorizationHeaderProps): Promise<string | null> {
    let header = null;

    try {
        const updatedAuthentication = await instance.acquireTokenSilent({
            account,
            authority: msalConfig.auth.authority as string,
            scopes: AUTH_REQUEST.scopes,
        });
        header = createAuthHeader(updatedAuthentication);
    } catch (err) {
        // eslint-disable-next-line no-console
        console.error('refreshAuthorizationHeader - failed to acquireTokenSilent');
        instance.logout();
    }

    return header;
}
