/* tslint:disable */
/* eslint-disable */
/**
 * Administration API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface FeatureFlagResponse
 */
export interface FeatureFlagResponse {
    /**
     * 
     * @type {string}
     * @memberof FeatureFlagResponse
     */
    name?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof FeatureFlagResponse
     */
    isEnabled: boolean;
    /**
     * 
     * @type {string}
     * @memberof FeatureFlagResponse
     */
    extraData?: string | null;
    /**
     * 
     * @type {Date}
     * @memberof FeatureFlagResponse
     */
    createdUtcDate?: Date | null;
    /**
     * 
     * @type {Date}
     * @memberof FeatureFlagResponse
     */
    updatedUtcDate?: Date | null;
}

export function FeatureFlagResponseFromJSON(json: any): FeatureFlagResponse {
    return FeatureFlagResponseFromJSONTyped(json, false);
}

export function FeatureFlagResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): FeatureFlagResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'name': !exists(json, 'name') ? undefined : json['name'],
        'isEnabled': json['isEnabled'],
        'extraData': !exists(json, 'extraData') ? undefined : json['extraData'],
        'createdUtcDate': !exists(json, 'createdUtcDate') ? undefined : (json['createdUtcDate'] === null ? null : new Date(json['createdUtcDate'])),
        'updatedUtcDate': !exists(json, 'updatedUtcDate') ? undefined : (json['updatedUtcDate'] === null ? null : new Date(json['updatedUtcDate'])),
    };
}

export function FeatureFlagResponseToJSON(value?: FeatureFlagResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'name': value.name,
        'isEnabled': value.isEnabled,
        'extraData': value.extraData,
        'createdUtcDate': value.createdUtcDate === undefined ? undefined : (value.createdUtcDate === null ? null : value.createdUtcDate.toISOString()),
        'updatedUtcDate': value.updatedUtcDate === undefined ? undefined : (value.updatedUtcDate === null ? null : value.updatedUtcDate.toISOString()),
    };
}

