import React, { useEffect, forwardRef, useRef } from 'react';
import styled from 'styled-components';

const SCheckbox = styled.input`
    width: 1.8rem;
    height: 1.8rem;
`;

type Props = {
    indeterminate?: boolean;
};

const useCombinedRefs = (...refs: any[]): React.MutableRefObject<any> => {
    const targetRef = useRef();

    useEffect(() => {
        refs.forEach((ref) => {
            if (!ref) return;

            if (typeof ref === 'function') {
                ref(targetRef.current);
            } else {
                ref.current = targetRef.current;
            }
        });
    }, [refs]);

    return targetRef;
};

export const Checkbox = forwardRef<HTMLInputElement, Props>(
    ({ indeterminate, ...rest }, ref: React.Ref<HTMLInputElement>) => {
        const defaultRef = useRef(null);
        const combinedRef = useCombinedRefs(ref, defaultRef);

        useEffect(() => {
            if (combinedRef?.current) {
                combinedRef.current.indeterminate = indeterminate ?? false;
            }
        }, [combinedRef, indeterminate]);

        return <SCheckbox type="checkbox" ref={combinedRef} {...rest} />;
    },
);
