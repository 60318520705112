/* tslint:disable */
/* eslint-disable */
/**
 * Administration API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    ClearEdenUserCreatedCheckCommand,
    ClearEdenUserCreatedCheckCommandFromJSON,
    ClearEdenUserCreatedCheckCommandToJSON,
    ProblemDetails,
    ProblemDetailsFromJSON,
    ProblemDetailsToJSON,
    UpdateEdenMemberCommand,
    UpdateEdenMemberCommandFromJSON,
    UpdateEdenMemberCommandToJSON,
} from '../models';

export interface ApiV1EdenHealthCleanedUserCheckPatchRequest {
    clearEdenUserCreatedCheckCommand?: ClearEdenUserCreatedCheckCommand;
}

export interface ApiV1EdenHealthMemberPutRequest {
    updateEdenMemberCommand?: UpdateEdenMemberCommand;
}

/**
 * 
 */
export class EdenHealthApi extends runtime.BaseAPI {

    /**
     */
    async apiV1EdenHealthCleanedUserCheckPatchRaw(requestParameters: ApiV1EdenHealthCleanedUserCheckPatchRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", []);
        }

        const response = await this.request({
            path: `/api/v1/eden-health/cleaned-user-check`,
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: ClearEdenUserCreatedCheckCommandToJSON(requestParameters.clearEdenUserCreatedCheckCommand),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async apiV1EdenHealthCleanedUserCheckPatch(requestParameters: ApiV1EdenHealthCleanedUserCheckPatchRequest, initOverrides?: RequestInit): Promise<void> {
        await this.apiV1EdenHealthCleanedUserCheckPatchRaw(requestParameters, initOverrides);
    }

    /**
     */
    async apiV1EdenHealthMemberPutRaw(requestParameters: ApiV1EdenHealthMemberPutRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", []);
        }

        const response = await this.request({
            path: `/api/v1/eden-health/member`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: UpdateEdenMemberCommandToJSON(requestParameters.updateEdenMemberCommand),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async apiV1EdenHealthMemberPut(requestParameters: ApiV1EdenHealthMemberPutRequest, initOverrides?: RequestInit): Promise<void> {
        await this.apiV1EdenHealthMemberPutRaw(requestParameters, initOverrides);
    }

}
