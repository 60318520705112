import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';

export enum FORM_FIELDS {
    FIRST_NAME = 'firstName',
    LAST_NAME = 'lastName',
    EMAIL = 'email',
    CARE_FIRST_MEMBER_ID = 'careFirstMemberId',
    PHONE_NUMBER = 'phoneNumber',
}
export type Form = {
    [FORM_FIELDS.FIRST_NAME]: string;
    [FORM_FIELDS.LAST_NAME]: string;
    [FORM_FIELDS.EMAIL]: string;
    [FORM_FIELDS.CARE_FIRST_MEMBER_ID]: string;
    [FORM_FIELDS.PHONE_NUMBER]: string;
};

export const validationSchema = yupResolver(
    yup.object().shape({
        [FORM_FIELDS.FIRST_NAME]: yup.string().trim(),
        [FORM_FIELDS.LAST_NAME]: yup.string().trim(),
        [FORM_FIELDS.EMAIL]: yup.string().trim(),
        [FORM_FIELDS.CARE_FIRST_MEMBER_ID]: yup.string().trim(),
        [FORM_FIELDS.PHONE_NUMBER]: yup.string().trim(),
    }),
);
