/* tslint:disable */
/* eslint-disable */
/**
 * Administration API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    CreateEligibilityOverrideCommand,
    CreateEligibilityOverrideCommandFromJSON,
    CreateEligibilityOverrideCommandToJSON,
    EligibilityOverrideDtoDataPageResult,
    EligibilityOverrideDtoDataPageResultFromJSON,
    EligibilityOverrideDtoDataPageResultToJSON,
    EligibilityOverrideDtoResult,
    EligibilityOverrideDtoResultFromJSON,
    EligibilityOverrideDtoResultToJSON,
    SortType,
    SortTypeFromJSON,
    SortTypeToJSON,
    UpdateEligibilityOverrideCommand,
    UpdateEligibilityOverrideCommandFromJSON,
    UpdateEligibilityOverrideCommandToJSON,
} from '../models';

export interface ApiV1EligibilityOverrideDetailsGetRequest {
    eligibilityOverrideId?: number;
}

export interface ApiV1EligibilityOverrideGetRequest {
    firstName?: string | null;
    lastName?: string | null;
    userId?: number | null;
    userObjectId?: string | null;
    careFirstMemberId?: string | null;
    sorterKey?: string;
    sorterSortType?: SortType;
    pageSize?: number | null;
    pageNumber?: number | null;
}

export interface ApiV1EligibilityOverridePatchRequest {
    updateEligibilityOverrideCommand?: UpdateEligibilityOverrideCommand;
}

export interface ApiV1EligibilityOverridePostRequest {
    createEligibilityOverrideCommand?: CreateEligibilityOverrideCommand;
}

/**
 * 
 */
export class EligibilityOverrideApi extends runtime.BaseAPI {

    /**
     */
    async apiV1EligibilityOverrideDetailsGetRaw(requestParameters: ApiV1EligibilityOverrideDetailsGetRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<EligibilityOverrideDtoResult>> {
        const queryParameters: any = {};

        if (requestParameters.eligibilityOverrideId !== undefined) {
            queryParameters['EligibilityOverrideId'] = requestParameters.eligibilityOverrideId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", []);
        }

        const response = await this.request({
            path: `/api/v1/eligibility-override/details`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => EligibilityOverrideDtoResultFromJSON(jsonValue));
    }

    /**
     */
    async apiV1EligibilityOverrideDetailsGet(requestParameters: ApiV1EligibilityOverrideDetailsGetRequest, initOverrides?: RequestInit): Promise<EligibilityOverrideDtoResult> {
        const response = await this.apiV1EligibilityOverrideDetailsGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async apiV1EligibilityOverrideGetRaw(requestParameters: ApiV1EligibilityOverrideGetRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<EligibilityOverrideDtoDataPageResult>> {
        const queryParameters: any = {};

        if (requestParameters.firstName !== undefined) {
            queryParameters['FirstName'] = requestParameters.firstName;
        }

        if (requestParameters.lastName !== undefined) {
            queryParameters['LastName'] = requestParameters.lastName;
        }

        if (requestParameters.userId !== undefined) {
            queryParameters['UserId'] = requestParameters.userId;
        }

        if (requestParameters.userObjectId !== undefined) {
            queryParameters['UserObjectId'] = requestParameters.userObjectId;
        }

        if (requestParameters.careFirstMemberId !== undefined) {
            queryParameters['CareFirstMemberId'] = requestParameters.careFirstMemberId;
        }

        if (requestParameters.sorterKey !== undefined) {
            queryParameters['Sorter.Key'] = requestParameters.sorterKey;
        }

        if (requestParameters.sorterSortType !== undefined) {
            queryParameters['Sorter.SortType'] = requestParameters.sorterSortType;
        }

        if (requestParameters.pageSize !== undefined) {
            queryParameters['PageSize'] = requestParameters.pageSize;
        }

        if (requestParameters.pageNumber !== undefined) {
            queryParameters['PageNumber'] = requestParameters.pageNumber;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", []);
        }

        const response = await this.request({
            path: `/api/v1/eligibility-override`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => EligibilityOverrideDtoDataPageResultFromJSON(jsonValue));
    }

    /**
     */
    async apiV1EligibilityOverrideGet(requestParameters: ApiV1EligibilityOverrideGetRequest, initOverrides?: RequestInit): Promise<EligibilityOverrideDtoDataPageResult> {
        const response = await this.apiV1EligibilityOverrideGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async apiV1EligibilityOverridePatchRaw(requestParameters: ApiV1EligibilityOverridePatchRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", []);
        }

        const response = await this.request({
            path: `/api/v1/eligibility-override`,
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: UpdateEligibilityOverrideCommandToJSON(requestParameters.updateEligibilityOverrideCommand),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async apiV1EligibilityOverridePatch(requestParameters: ApiV1EligibilityOverridePatchRequest, initOverrides?: RequestInit): Promise<void> {
        await this.apiV1EligibilityOverridePatchRaw(requestParameters, initOverrides);
    }

    /**
     */
    async apiV1EligibilityOverridePostRaw(requestParameters: ApiV1EligibilityOverridePostRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", []);
        }

        const response = await this.request({
            path: `/api/v1/eligibility-override`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CreateEligibilityOverrideCommandToJSON(requestParameters.createEligibilityOverrideCommand),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async apiV1EligibilityOverridePost(requestParameters: ApiV1EligibilityOverridePostRequest, initOverrides?: RequestInit): Promise<void> {
        await this.apiV1EligibilityOverridePostRaw(requestParameters, initOverrides);
    }

}
