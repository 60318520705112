/* tslint:disable */
/* eslint-disable */
/**
 * Administration API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    ProblemDetails,
    ProblemDetailsFromJSON,
    ProblemDetailsToJSON,
    SortType,
    SortTypeFromJSON,
    SortTypeToJSON,
    UserActivityLogDtoDataPageResult,
    UserActivityLogDtoDataPageResultFromJSON,
    UserActivityLogDtoDataPageResultToJSON,
} from '../models';

export interface ApiV1UserActivityLogGetRequest {
    userId: number;
    sorterKey?: string;
    sorterSortType?: SortType;
    pageSize?: number | null;
    pageNumber?: number | null;
}

/**
 * 
 */
export class UserActivityLogApi extends runtime.BaseAPI {

    /**
     */
    async apiV1UserActivityLogGetRaw(requestParameters: ApiV1UserActivityLogGetRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<UserActivityLogDtoDataPageResult>> {
        if (requestParameters.userId === null || requestParameters.userId === undefined) {
            throw new runtime.RequiredError('userId','Required parameter requestParameters.userId was null or undefined when calling apiV1UserActivityLogGet.');
        }

        const queryParameters: any = {};

        if (requestParameters.userId !== undefined) {
            queryParameters['UserId'] = requestParameters.userId;
        }

        if (requestParameters.sorterKey !== undefined) {
            queryParameters['Sorter.Key'] = requestParameters.sorterKey;
        }

        if (requestParameters.sorterSortType !== undefined) {
            queryParameters['Sorter.SortType'] = requestParameters.sorterSortType;
        }

        if (requestParameters.pageSize !== undefined) {
            queryParameters['PageSize'] = requestParameters.pageSize;
        }

        if (requestParameters.pageNumber !== undefined) {
            queryParameters['PageNumber'] = requestParameters.pageNumber;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", []);
        }

        const response = await this.request({
            path: `/api/v1/user-activity-log`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => UserActivityLogDtoDataPageResultFromJSON(jsonValue));
    }

    /**
     */
    async apiV1UserActivityLogGet(requestParameters: ApiV1UserActivityLogGetRequest, initOverrides?: RequestInit): Promise<UserActivityLogDtoDataPageResult> {
        const response = await this.apiV1UserActivityLogGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
