import React, { ReactNode } from 'react';
import styled from 'styled-components';

const STitle = styled.h3`
    font-family: ${({ theme }) => theme.FONT.FAMILY.MACKINAC_MEDIUM};
    font-weight: ${({ theme }) => theme.FONT.WEIGHT.MEDIUM};
    font-size: ${({ theme }) => theme.FONT.SIZE.BIG};
    line-height: 3rem;
    margin-bottom: 3rem;
`;

type Props = {
    children: ReactNode;
    className?: string;
};

export const Title = ({ children, className }: Props) => {
    return <STitle className={className}>{children}</STitle>;
};
