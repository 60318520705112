import { isAccessibleForUserRoles, ROLES } from 'helpers/isAccessibleForUserRoles';
import React, { ReactNode } from 'react';
import { Navigate } from 'react-router-dom';

type Props = {
    userRoles: ROLES[];
    children: ReactNode;
    rolesWithAccess: ROLES[];
};

export const ProtectedRoute = ({ userRoles, rolesWithAccess, children }: Props) => {
    if (!isAccessibleForUserRoles({ rolesWithAccess, userRoles })) {
        return <Navigate to={'/'} replace />;
    }
    return <>{children}</>;
};
