/* tslint:disable */
/* eslint-disable */
/**
 * Administration API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface FeatureFlagRequest
 */
export interface FeatureFlagRequest {
    /**
     * 
     * @type {string}
     * @memberof FeatureFlagRequest
     */
    name: string;
    /**
     * 
     * @type {boolean}
     * @memberof FeatureFlagRequest
     */
    isEnabled: boolean;
    /**
     * 
     * @type {string}
     * @memberof FeatureFlagRequest
     */
    extraData?: string | null;
}

export function FeatureFlagRequestFromJSON(json: any): FeatureFlagRequest {
    return FeatureFlagRequestFromJSONTyped(json, false);
}

export function FeatureFlagRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): FeatureFlagRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'name': json['name'],
        'isEnabled': json['isEnabled'],
        'extraData': !exists(json, 'extraData') ? undefined : json['extraData'],
    };
}

export function FeatureFlagRequestToJSON(value?: FeatureFlagRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'name': value.name,
        'isEnabled': value.isEnabled,
        'extraData': value.extraData,
    };
}

