/* tslint:disable */
/* eslint-disable */
/**
 * Administration API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface CreateEligibilityOverrideCommand
 */
export interface CreateEligibilityOverrideCommand {
    /**
     * 
     * @type {number}
     * @memberof CreateEligibilityOverrideCommand
     */
    userId: number;
    /**
     * 
     * @type {string}
     * @memberof CreateEligibilityOverrideCommand
     */
    memberIdEligibilityResult: string;
    /**
     * 
     * @type {boolean}
     * @memberof CreateEligibilityOverrideCommand
     */
    active: boolean;
    /**
     * 
     * @type {string}
     * @memberof CreateEligibilityOverrideCommand
     */
    comments?: string | null;
}

export function CreateEligibilityOverrideCommandFromJSON(json: any): CreateEligibilityOverrideCommand {
    return CreateEligibilityOverrideCommandFromJSONTyped(json, false);
}

export function CreateEligibilityOverrideCommandFromJSONTyped(json: any, ignoreDiscriminator: boolean): CreateEligibilityOverrideCommand {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'userId': json['userId'],
        'memberIdEligibilityResult': json['memberIdEligibilityResult'],
        'active': json['active'],
        'comments': !exists(json, 'comments') ? undefined : json['comments'],
    };
}

export function CreateEligibilityOverrideCommandToJSON(value?: CreateEligibilityOverrideCommand | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'userId': value.userId,
        'memberIdEligibilityResult': value.memberIdEligibilityResult,
        'active': value.active,
        'comments': value.comments,
    };
}

