import React from 'react';
import styled from 'styled-components';
import { Control, Path, useController } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { defaultTextStyles } from 'styles/styles';

export type Option = {
    label: string;
    value: string;
};

const SSelect = styled.select`
    ${defaultTextStyles}
    border: 1px solid ${({ theme }) => theme.COLORS.TAN_THREE};
    background-color: ${({ theme }) => theme.COLORS.TAN_TWO};
    padding: 1rem;
`;

type Props<T> = {
    name: Path<T>;
    control: Control<T>;
    options: Option[];
};

export const SelectInput = <T extends Record<string, unknown>>({
    name,
    control,
    options,
}: Props<T>) => {
    const { t } = useTranslation();
    const { field } = useController({
        name,
        control,
    });
    const { value, onChange } = field;

    return (
        <SSelect value={value as string} onChange={(event) => onChange(event.target.value)}>
            <option value="" disabled selected hidden>
                {t('common.selectItem')}
            </option>
            {options.map((option, index) => (
                <option key={index} value={option.value}>
                    {option.label}
                </option>
            ))}
        </SSelect>
    );
};
