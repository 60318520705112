/* tslint:disable */
/* eslint-disable */
/**
 * Administration API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    UserProviderRelationship,
    UserProviderRelationshipFromJSON,
    UserProviderRelationshipFromJSONTyped,
    UserProviderRelationshipToJSON,
} from './';

/**
 * 
 * @export
 * @interface UserResponse
 */
export interface UserResponse {
    /**
     * 
     * @type {string}
     * @memberof UserResponse
     */
    firstName?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UserResponse
     */
    lastName?: string | null;
    /**
     * 
     * @type {Date}
     * @memberof UserResponse
     */
    dateOfBirth?: Date | null;
    /**
     * 
     * @type {string}
     * @memberof UserResponse
     */
    email?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UserResponse
     */
    careFirstMemberId?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UserResponse
     */
    userObjectId: string;
    /**
     * 
     * @type {UserProviderRelationship}
     * @memberof UserResponse
     */
    userRelationshipId?: UserProviderRelationship;
    /**
     * 
     * @type {number}
     * @memberof UserResponse
     */
    id: number;
    /**
     * 
     * @type {string}
     * @memberof UserResponse
     */
    preferredFirstName?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UserResponse
     */
    gender?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UserResponse
     */
    streetAddress?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UserResponse
     */
    city?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UserResponse
     */
    state?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UserResponse
     */
    zipCode?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof UserResponse
     */
    termsOfServiceConsent: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof UserResponse
     */
    marketingConsent: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof UserResponse
     */
    privacyPolicyConsent: boolean;
    /**
     * 
     * @type {string}
     * @memberof UserResponse
     */
    phoneNumber?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UserResponse
     */
    streetAddress2?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof UserResponse
     */
    edenHealthMemberStatus: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof UserResponse
     */
    edenUserAlreadyExists: boolean;
}

export function UserResponseFromJSON(json: any): UserResponse {
    return UserResponseFromJSONTyped(json, false);
}

export function UserResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): UserResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'firstName': !exists(json, 'firstName') ? undefined : json['firstName'],
        'lastName': !exists(json, 'lastName') ? undefined : json['lastName'],
        'dateOfBirth': !exists(json, 'dateOfBirth') ? undefined : (json['dateOfBirth'] === null ? null : new Date(json['dateOfBirth'])),
        'email': !exists(json, 'email') ? undefined : json['email'],
        'careFirstMemberId': !exists(json, 'careFirstMemberId') ? undefined : json['careFirstMemberId'],
        'userObjectId': json['userObjectId'],
        'userRelationshipId': !exists(json, 'userRelationshipId') ? undefined : UserProviderRelationshipFromJSON(json['userRelationshipId']),
        'id': json['id'],
        'preferredFirstName': !exists(json, 'preferredFirstName') ? undefined : json['preferredFirstName'],
        'gender': !exists(json, 'gender') ? undefined : json['gender'],
        'streetAddress': !exists(json, 'streetAddress') ? undefined : json['streetAddress'],
        'city': !exists(json, 'city') ? undefined : json['city'],
        'state': !exists(json, 'state') ? undefined : json['state'],
        'zipCode': !exists(json, 'zipCode') ? undefined : json['zipCode'],
        'termsOfServiceConsent': json['termsOfServiceConsent'],
        'marketingConsent': json['marketingConsent'],
        'privacyPolicyConsent': json['privacyPolicyConsent'],
        'phoneNumber': !exists(json, 'phoneNumber') ? undefined : json['phoneNumber'],
        'streetAddress2': !exists(json, 'streetAddress2') ? undefined : json['streetAddress2'],
        'edenHealthMemberStatus': json['edenHealthMemberStatus'],
        'edenUserAlreadyExists': json['edenUserAlreadyExists'],
    };
}

export function UserResponseToJSON(value?: UserResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'firstName': value.firstName,
        'lastName': value.lastName,
        'dateOfBirth': value.dateOfBirth === undefined ? undefined : (value.dateOfBirth === null ? null : value.dateOfBirth.toISOString()),
        'email': value.email,
        'careFirstMemberId': value.careFirstMemberId,
        'userObjectId': value.userObjectId,
        'userRelationshipId': UserProviderRelationshipToJSON(value.userRelationshipId),
        'id': value.id,
        'preferredFirstName': value.preferredFirstName,
        'gender': value.gender,
        'streetAddress': value.streetAddress,
        'city': value.city,
        'state': value.state,
        'zipCode': value.zipCode,
        'termsOfServiceConsent': value.termsOfServiceConsent,
        'marketingConsent': value.marketingConsent,
        'privacyPolicyConsent': value.privacyPolicyConsent,
        'phoneNumber': value.phoneNumber,
        'streetAddress2': value.streetAddress2,
        'edenHealthMemberStatus': value.edenHealthMemberStatus,
        'edenUserAlreadyExists': value.edenUserAlreadyExists,
    };
}

