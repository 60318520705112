/* tslint:disable */
/* eslint-disable */
/**
 * Administration API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface CreateUserCsSwatNoteCommand
 */
export interface CreateUserCsSwatNoteCommand {
    /**
     * 
     * @type {number}
     * @memberof CreateUserCsSwatNoteCommand
     */
    userId: number;
    /**
     * 
     * @type {string}
     * @memberof CreateUserCsSwatNoteCommand
     */
    note: string;
}

export function CreateUserCsSwatNoteCommandFromJSON(json: any): CreateUserCsSwatNoteCommand {
    return CreateUserCsSwatNoteCommandFromJSONTyped(json, false);
}

export function CreateUserCsSwatNoteCommandFromJSONTyped(json: any, ignoreDiscriminator: boolean): CreateUserCsSwatNoteCommand {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'userId': json['userId'],
        'note': json['note'],
    };
}

export function CreateUserCsSwatNoteCommandToJSON(value?: CreateUserCsSwatNoteCommand | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'userId': value.userId,
        'note': value.note,
    };
}

