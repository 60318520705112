import React from 'react';
import { StyledContainer } from 'components/styled-container';
import { Title } from 'components/title';
import { useTranslation } from 'react-i18next';
import { EligibilityOverridesSearchForm } from 'views/eligibility-overrides-navigator/eligibility-overrides-search/components/eligibility-overrides-search-form';
import { EligibilityOverridesResults } from 'views/eligibility-overrides-navigator/eligibility-overrides-search/components/eligibility-overrides-results';
import { BUTTON_VARIANTS, StyledButton } from 'components/styled-button';
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';
import { ROUTES } from 'constants/routes';
import { Form } from 'views/eligibility-overrides-navigator/eligibility-overrides-search/components/eligibility-overrides-search-form/validators';
import queryString from 'query-string';
import { DEFAULT_PAGE_SIZE } from 'helpers/getPageCount';
import { useTableState } from 'hooks/useTableState';

const SRow = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
`;

export const EligibilityOverridesSearch = () => {
    const { setSearchParams, setPageNumber, setSortByList, resetTableState, searchData } =
        useTableState();

    const { t } = useTranslation();
    const navigate = useNavigate();

    const onSubmit = (data: Form): void => {
        resetTableState.current = true;
        setSearchParams(
            queryString.stringify(
                { ...data, pageNumber: 0, pageSize: DEFAULT_PAGE_SIZE },
                { skipNull: true, skipEmptyString: true },
            ),
        );
    };

    return (
        <StyledContainer>
            <SRow>
                <Title>{t('eligibilityOverridesSearch.title')}</Title>
                <StyledButton
                    variant={BUTTON_VARIANTS.TRANSPARENT}
                    onClick={() => navigate(ROUTES.ELIGIBILITY_OVERRIDE_ADD)}
                >
                    {t('eligibilityOverridesSearch.addEligibilityOverride')}
                </StyledButton>
            </SRow>
            <EligibilityOverridesSearchForm
                onSubmit={onSubmit}
                eligibilityOverridesSearchData={searchData}
            />
            <EligibilityOverridesResults
                setPageNumber={setPageNumber}
                resetTableState={resetTableState}
                eligibilityOverridesSearchData={searchData}
                setSortByList={setSortByList}
            />
        </StyledContainer>
    );
};
