import React from 'react';
import styled from 'styled-components';
import { StyledButton } from 'components/styled-button';
import { t } from 'i18next';
import { Option, SelectInput } from 'components/inputs/select-input';
import { useForm } from 'react-hook-form';
import {
    Form,
    FORM_FIELDS,
    validationSchema,
} from 'components/table/components/mass-action-form/validators';
import { RowData } from 'components/table/types';

const SContainer = styled.div`
    margin: 2rem 0;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    flex-direction: row;
`;

const SSubmitButton = styled(StyledButton)`
    width: 10rem;
    padding: 1rem;
    margin-left: 1rem;
`;

type Props = {
    massActions: Option[];
    selectedRows: RowData[];
    handleMassAction: (actionValue: string, selectedRows: RowData[]) => void;
};

export const MassActionForm = ({ massActions, selectedRows, handleMassAction }: Props) => {
    const { formState, control, handleSubmit } = useForm<Form>({
        defaultValues: {
            [FORM_FIELDS.ACTION]: '',
        },
        resolver: validationSchema,
        mode: 'onChange',
    });

    const onSubmit = (data: Form) => {
        handleMassAction(data.action, selectedRows);
    };

    return (
        <SContainer>
            <SelectInput name={FORM_FIELDS.ACTION} control={control} options={massActions} />
            <SSubmitButton
                disabled={!formState.isValid || !selectedRows.length}
                onClick={handleSubmit(onSubmit)}
            >
                {t('common.submit')}
            </SSubmitButton>
        </SContainer>
    );
};
