import React from 'react';
import styled from 'styled-components';
import { useAccount } from 'api/auth';
import { useMsal } from '@azure/msal-react';
import { StyledText } from 'components/styled-text';
import { StyledButton } from 'components/styled-button';
import { useTranslation } from 'react-i18next';
import { NavLink } from 'react-router-dom';
import { getNavigationLinks } from 'components/header/constants';
import { ROLES } from 'helpers/isAccessibleForUserRoles';

const SContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    padding: 2rem;
    position: sticky;
    z-index: 1;
    top: 0;
    background-color: ${({ theme }) => theme.COLORS.WHITE};
    box-shadow: 0px 6px 11px -7px rgba(199, 198, 195, 1);
    ${({ theme }) => theme.MEDIA.MAX_768`
        flex-direction: column;
        align-items: flex-start;
    `};
`;

const SLogoutButton = styled(StyledButton)`
    width: 14rem;
    padding: 1rem 0;
`;

const SUl = styled.ul`
    display: flex;
    list-style-type: none;
    flex: 1;
    max-width: 144rem;
    padding: 0 3rem;
    ${({ theme }) => theme.MEDIA.MAX_768`
        flex-direction: column;
        align-items: flex-start;
        padding: 1rem 0;
    `};
`;

const SNavigationLink = styled(NavLink)`
    color: ${({ theme }) => theme.COLORS.BLACK};
    font-size: ${({ theme }) => theme.FONT.SIZE.REGULAR};
    font-family: ${({ theme }) => theme.FONT.FAMILY.WORK_SANS};
    line-height: 2.4rem;
    margin-right: 3rem;
    &.active {
        color: ${({ theme }) => theme.COLORS.PRIMARY};
        font-family: ${({ theme }) => theme.FONT.FAMILY.WORK_SANS_MEDIUM};
    }
`;

export const Header = () => {
    const account = useAccount();
    const { instance } = useMsal();
    const { t } = useTranslation();

    const onLogOut = () => instance.logout({ account });

    const { name, username, idTokenClaims } = account || {};
    const userRoles = (idTokenClaims as { roles: ROLES[] }).roles;

    return (
        <SContainer>
            <StyledText>{t('header.loggedIn', { name: name || username })}</StyledText>
            <SUl>
                {getNavigationLinks(t, userRoles).map((item) => (
                    <li key={item.name}>
                        <SNavigationLink to={`/${item.route}`}>{item.name}</SNavigationLink>
                    </li>
                ))}
            </SUl>
            <SLogoutButton onClick={onLogOut}>{t('header.logout')}</SLogoutButton>
        </SContainer>
    );
};
