/* tslint:disable */
/* eslint-disable */
/**
 * Administration API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    CreateSimpleBillCommand,
    CreateSimpleBillCommandFromJSON,
    CreateSimpleBillCommandToJSON,
    ProblemDetails,
    ProblemDetailsFromJSON,
    ProblemDetailsToJSON,
    SimpleBillDtoIEnumerableResult,
    SimpleBillDtoIEnumerableResultFromJSON,
    SimpleBillDtoIEnumerableResultToJSON,
} from '../models';

export interface ApiV1SimpleBillsGetRequest {
    userId?: number;
}

export interface ApiV1SimpleBillsPostRequest {
    createSimpleBillCommand?: CreateSimpleBillCommand;
}

/**
 * 
 */
export class SimpleBillsApi extends runtime.BaseAPI {

    /**
     */
    async apiV1SimpleBillsGetRaw(requestParameters: ApiV1SimpleBillsGetRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<SimpleBillDtoIEnumerableResult>> {
        const queryParameters: any = {};

        if (requestParameters.userId !== undefined) {
            queryParameters['UserId'] = requestParameters.userId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", []);
        }

        const response = await this.request({
            path: `/api/v1/simple-bills`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => SimpleBillDtoIEnumerableResultFromJSON(jsonValue));
    }

    /**
     */
    async apiV1SimpleBillsGet(requestParameters: ApiV1SimpleBillsGetRequest, initOverrides?: RequestInit): Promise<SimpleBillDtoIEnumerableResult> {
        const response = await this.apiV1SimpleBillsGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async apiV1SimpleBillsPostRaw(requestParameters: ApiV1SimpleBillsPostRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", []);
        }

        const response = await this.request({
            path: `/api/v1/simple-bills`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CreateSimpleBillCommandToJSON(requestParameters.createSimpleBillCommand),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async apiV1SimpleBillsPost(requestParameters: ApiV1SimpleBillsPostRequest, initOverrides?: RequestInit): Promise<void> {
        await this.apiV1SimpleBillsPostRaw(requestParameters, initOverrides);
    }

}
