import React, { memo } from 'react';
import styled, { css } from 'styled-components';
import { defaultTextStyles } from 'styles/styles';
import { ReactComponent as LoadingSpinner } from 'assets/svg/ico_loading_spinner.svg';
import { useTranslation } from 'react-i18next';
import { RowData } from 'components/table/types';
import { Row, TableBodyPropGetter, TableBodyProps } from 'react-table';
import isEqual from 'lodash.isequal';

const defaultTrStyles = css`
    padding: 1rem 0;
    display: flex;
`;

const STBody = styled.tbody`
    position: relative;
    height: 100px;
`;

const SCenterTd = styled.td`
    ${defaultTextStyles}
    text-align: center;
`;

const STr = styled.tr`
    ${defaultTrStyles}
    align-items: flex-start;
    border-bottom: 1px solid ${({ theme }) => theme.COLORS.GRAY};
`;

const STd = styled.td`
    ${defaultTextStyles}
    overflow-wrap: break-word;
    margin-right: 1rem;
`;

type Props = {
    page: Row<RowData>[];
    getTableBodyProps: (propGetter?: TableBodyPropGetter<RowData> | undefined) => TableBodyProps;
    prepareRow: (row: Row<RowData>) => void;
    isLoading: boolean;
    isDataAvailable: boolean;
};

export const TableBody = memo(
    ({ page, getTableBodyProps, isLoading, isDataAvailable, prepareRow }: Props) => {
        const { t } = useTranslation();

        return (
            <STBody {...getTableBodyProps}>
                {(isLoading || !isDataAvailable) && (
                    <tr>
                        <SCenterTd>{isLoading ? <LoadingSpinner /> : t('table.noData')}</SCenterTd>
                    </tr>
                )}
                {page.map((row) => {
                    prepareRow(row);
                    const { key, ...restRowProps } = row.getRowProps();
                    return (
                        <STr key={key} {...restRowProps}>
                            {row.cells.map((cell) => {
                                const { key, ...restCellProps } = cell.getCellProps();
                                return (
                                    <STd key={key} {...restCellProps}>
                                        {cell.render('Cell')}
                                    </STd>
                                );
                            })}
                        </STr>
                    );
                })}
            </STBody>
        );
    },
    isEqual,
);
