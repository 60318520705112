import React from 'react';
import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';
import { StyledInput } from 'components/inputs/styled-input';
import styled from 'styled-components';
import { StyledSubmitButton } from 'components/styled-button';
import {
    Form,
    FORM_FIELDS,
    validationSchema,
} from 'views/user-navigator/user-search/components/user-search-form/validators';
import { MaskedInput } from 'components/inputs/masked-input';
import { MASKS } from 'constants/masks';

const SContainer = styled.form`
    display: flex;
    flex-direction: column;
    margin-bottom: 2rem;
    padding-bottom: 4rem;
    border-bottom: 1px solid ${({ theme }) => theme.COLORS.GRAY};
`;

const SSubContainer = styled.div`
    display: grid;
    grid-template-columns: 30% 30% 30%;
    grid-gap: 5%;
    margin-bottom: 2rem;
    ${({ theme }) => theme.MEDIA.MAX_1024`
        grid-template-columns: 48% 48%;
        grid-gap: 4%;
    `};
    ${({ theme }) => theme.MEDIA.MAX_768`
        grid-template-columns: 100%;
        grid-gap: 0%;
    `};
`;

type Props = {
    onSubmit: (data: Form) => void;
    userSearchData: Partial<Form>;
};

export const UserSearchForm = ({ onSubmit, userSearchData }: Props) => {
    const { t } = useTranslation();

    const { firstName, lastName, email, careFirstMemberId, phoneNumber } = userSearchData;

    const { formState, control, handleSubmit } = useForm<Form>({
        resolver: validationSchema,
        defaultValues: {
            [FORM_FIELDS.FIRST_NAME]: firstName || '',
            [FORM_FIELDS.LAST_NAME]: lastName || '',
            [FORM_FIELDS.EMAIL]: email || '',
            [FORM_FIELDS.CARE_FIRST_MEMBER_ID]: careFirstMemberId || '',
            [FORM_FIELDS.PHONE_NUMBER]: phoneNumber || '',
        },
    });

    return (
        <SContainer onSubmit={handleSubmit(onSubmit)}>
            <SSubContainer>
                <StyledInput
                    name={FORM_FIELDS.FIRST_NAME}
                    control={control}
                    label={t('userSearch.firstName')}
                    error={formState.errors.firstName}
                />
                <StyledInput
                    name={FORM_FIELDS.LAST_NAME}
                    control={control}
                    label={t('userSearch.lastName')}
                    error={formState.errors.lastName}
                />
                <StyledInput
                    name={FORM_FIELDS.CARE_FIRST_MEMBER_ID}
                    control={control}
                    label={t('userSearch.careFirstMemberId')}
                    error={formState.errors.careFirstMemberId}
                />
                <StyledInput
                    name={FORM_FIELDS.EMAIL}
                    control={control}
                    label={t('userSearch.email')}
                    error={formState.errors.email}
                />
                <MaskedInput
                    name={FORM_FIELDS.PHONE_NUMBER}
                    control={control}
                    label={t('userSearch.phoneNumber')}
                    error={formState.errors.phoneNumber}
                    mask={MASKS.PHONE_NUMBER}
                />
            </SSubContainer>
            <StyledSubmitButton>{t('common.search')}</StyledSubmitButton>
        </SContainer>
    );
};
