import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';

export enum FORM_FIELDS {
    NAME = 'name',
    EXTRA_DATA = 'extraData',
    IS_ENABLED = 'isEnabled',
}
export type Form = {
    [FORM_FIELDS.NAME]: string;
    [FORM_FIELDS.EXTRA_DATA]: string;
    [FORM_FIELDS.IS_ENABLED]: boolean;
};

export const validationSchema = yupResolver(
    yup.object().shape({
        [FORM_FIELDS.NAME]: yup.string().trim().max(255).required('errors.fieldIsRequired'),
        [FORM_FIELDS.EXTRA_DATA]: yup.string().trim().max(4000),
        [FORM_FIELDS.IS_ENABLED]: yup.boolean().required('errors.fieldIsRequired'),
    }),
);
