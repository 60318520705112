import React from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { LabelWithValue } from 'components/label-with-value';
import { StyledText } from 'components/styled-text';
import { UserDeviceWithFirebaseDeviceIdDto } from 'generated';
import dayjs from 'dayjs';

const SLi = styled.li`
    padding: 2rem;
    border-radius: 0.5rem;
    background-color: ${({ theme }) => theme.COLORS.WHITE};
    box-shadow: 0px 6px 11px -7px rgba(199, 198, 195, 1);
    margin-bottom: 2rem;
`;

const SUl = styled.ul`
    list-style-type: none;
`;

const SDateText = styled(StyledText)`
    color: ${({ theme }) => theme.COLORS.PRIMARY};
    margin-bottom: 1rem;
`;

const SLabelWithValue = styled(LabelWithValue)`
    padding-bottom: 0;
    margin-bottom: 0;
    border-bottom: 0;
`;

type Props = { isFirebaseDeviceIdVisible: boolean } & Pick<
    UserDeviceWithFirebaseDeviceIdDto,
    | 'appVersion'
    | 'deviceType'
    | 'firebaseDeviceId'
    | 'lastFirebaseNotificationUtcDate'
    | 'latestLogin'
>;

export const DeviceItem = ({
    appVersion,
    deviceType,
    firebaseDeviceId,
    lastFirebaseNotificationUtcDate,
    latestLogin,
    isFirebaseDeviceIdVisible,
}: Props) => {
    const { t } = useTranslation();

    return (
        <SLi>
            <SDateText>{`${t('userDevices.latestLogin')}: ${
                latestLogin ? dayjs(latestLogin).format('MM/DD/YYYY hh:mm') : 'N/A'
            }`}</SDateText>
            <SUl>
                <LabelWithValue label={t('userDevices.appVersion')} value={appVersion} />
                <LabelWithValue label={t('userDevices.deviceType')} value={deviceType} />
                {isFirebaseDeviceIdVisible && (
                    <LabelWithValue
                        label={t('userDevices.firebaseDeviceId')}
                        value={firebaseDeviceId}
                    />
                )}
                <SLabelWithValue
                    label={t('userDevices.lastFirebaseNotification')}
                    value={dayjs(lastFirebaseNotificationUtcDate).format('MM/DD/YYYY hh:mm')}
                />
            </SUl>
        </SLi>
    );
};
