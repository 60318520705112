export enum ROLES {
    ADMIN = 'cx_workbench_administrator',
    SUPPORT = 'cx_workbench_support',
    NAVIGATOR = 'cx_workbench_navigator',
}

type Props = {
    rolesWithAccess: ROLES[];
    userRoles: ROLES[];
};

export const isAccessibleForUserRoles = ({ rolesWithAccess, userRoles }: Props) => {
    return !!userRoles.find((role) => {
        return rolesWithAccess.includes(role);
    });
};
