import React from 'react';
import { useTranslation } from 'react-i18next';
import { BUTTON_VARIANTS, StyledButton } from 'components/styled-button';
import { useState } from 'react';
import styled from 'styled-components';
import { StyledText } from 'components/styled-text';
import { ReactComponent as PlusIcon } from 'assets/svg/ico_plus.svg';
import { ReactComponent as LoadingSpinner } from 'assets/svg/ico_loading_spinner.svg';
import { CreateEditFlag } from 'views/feature-flags-navigator/components/create-edit-flag';
import { FeatureFlagResponse } from 'generated';
import { FEATURE_FLAGS } from 'views/feature-flags-navigator/constants';

const SRow = styled.div`
    display: flex;
    justify-content: flex-end;
    align-items: flex-start;
    margin-bottom: 2rem;
`;

const SUl = styled.ul`
    list-style-type: none;
`;

const SCenter = styled.div`
    display: flex;
    justify-content: center;
    margin-top: 3rem;
`;

const SAddFirstNoteButton = styled.button`
    justify-content: center;
    align-items: center;
    display: flex;
    height: 15rem;
    width: 100%;
    cursor: pointer;
    border-radius: 0.5rem;
    background-color: ${({ theme }) => theme.COLORS.TAN_TWO};
    margin-bottom: 2rem;
`;

const SPlusIcon = styled(PlusIcon)`
    margin-right: 1rem;
`;

type Props = {
    data: FeatureFlagResponse[];
    isLoading: boolean;
    type: FEATURE_FLAGS;
};

export const FeatureFlagsList = ({ data, isLoading, type }: Props) => {
    const { t } = useTranslation();
    const [editedName, setEditedName] = useState<string | undefined | null>();

    const isCreateMode = editedName === '';

    return (
        <>
            <SRow>
                <StyledButton
                    variant={BUTTON_VARIANTS.TRANSPARENT}
                    onClick={() => setEditedName('')}
                >
                    {t('featureFlags.addNewFlag')}
                </StyledButton>
            </SRow>
            {isCreateMode && (
                <CreateEditFlag type={type} setEditedName={setEditedName} name={''} as="div" />
            )}
            {isLoading ? (
                <SCenter>
                    <LoadingSpinner />
                </SCenter>
            ) : data.length ? (
                <SUl>
                    {data.map((item) => {
                        const { name, extraData, isEnabled } = item;
                        return (
                            <CreateEditFlag
                                key={name}
                                type={type}
                                setEditedName={setEditedName}
                                editedName={editedName}
                                name={name}
                                extraData={extraData}
                                isEnabled={isEnabled}
                            />
                        );
                    })}
                </SUl>
            ) : (
                !isCreateMode && (
                    <SAddFirstNoteButton onClick={() => setEditedName('')}>
                        <SPlusIcon />
                        <StyledText>{t('featureFlags.addNewFlag')}</StyledText>
                    </SAddFirstNoteButton>
                )
            )}
        </>
    );
};
