import React from 'react';
import { StyledContainer } from 'components/styled-container';
import { Title } from 'components/title';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { CreateEditEligibilityOverrideForm } from 'views/eligibility-overrides-navigator/create-edit-eligibility-override/components/create-edit-eligibility-override-form';

export const CreateEditEligibilityOverride = () => {
    const { t } = useTranslation();
    const params = useParams();
    const { eligibilityOverrideId, id } = params;

    return (
        <StyledContainer>
            <Title>
                {t(
                    `createEditEligibilityOverride.${
                        eligibilityOverrideId ? 'editEligibilityOverride' : 'addEligibilityOverride'
                    }`,
                )}
            </Title>
            <CreateEditEligibilityOverrideForm
                eligibilityOverrideId={Number(eligibilityOverrideId) || undefined}
                userId={Number(id) || undefined}
            />
        </StyledContainer>
    );
};
