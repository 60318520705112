import useSWR, { SWRResponse } from 'swr';
import { useAuthorized } from 'api';
import { FeatureFlagResponseIEnumerableResult, FeatureFlagsApi } from 'generated';

type Response = SWRResponse<FeatureFlagResponseIEnumerableResult> & {
    isLoading: boolean;
};

export const useFeatureFlagsBackend = (): Response => {
    const api = useAuthorized(FeatureFlagsApi);
    const { data, error, ...rest } = useSWR('apiV1FeatureFlagsBackendGet', () =>
        api.apiV1FeatureFlagsBackendGet(),
    );
    const isLoading = data === undefined && !error;

    return { data, error, isLoading, ...rest };
};
