/* tslint:disable */
/* eslint-disable */
/**
 * Administration API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    CreateUserCsSwatNoteCommand,
    CreateUserCsSwatNoteCommandFromJSON,
    CreateUserCsSwatNoteCommandToJSON,
    ProblemDetails,
    ProblemDetailsFromJSON,
    ProblemDetailsToJSON,
    SortType,
    SortTypeFromJSON,
    SortTypeToJSON,
    UpdateUserNoteRequest,
    UpdateUserNoteRequestFromJSON,
    UpdateUserNoteRequestToJSON,
    UserCsSwatNoteResponseIEnumerableResult,
    UserCsSwatNoteResponseIEnumerableResultFromJSON,
    UserCsSwatNoteResponseIEnumerableResultToJSON,
    UserResponseDataPageResult,
    UserResponseDataPageResultFromJSON,
    UserResponseDataPageResultToJSON,
    UserResponseResult,
    UserResponseResultFromJSON,
    UserResponseResultToJSON,
} from '../models';

export interface ApiV1UserGetRequest {
    firstName?: string | null;
    lastName?: string | null;
    email?: string | null;
    careFirstMemberId?: string | null;
    userObjectId?: string | null;
    id?: number | null;
    phoneNumber?: string | null;
    sorterKey?: string;
    sorterSortType?: SortType;
    pageSize?: number | null;
    pageNumber?: number | null;
}

export interface ApiV1UserIdGetRequest {
    id: number;
}

export interface ApiV1UserIdWipePatchRequest {
    id: number;
}

export interface ApiV1UserNotesGetRequest {
    userId?: number;
}

export interface ApiV1UserNotesIdDeleteRequest {
    id: string;
}

export interface ApiV1UserNotesIdPatchRequest {
    id: string;
    updateUserNoteRequest?: UpdateUserNoteRequest;
}

export interface ApiV1UserNotesPostRequest {
    createUserCsSwatNoteCommand?: CreateUserCsSwatNoteCommand;
}

/**
 * 
 */
export class UserApi extends runtime.BaseAPI {

    /**
     */
    async apiV1UserGetRaw(requestParameters: ApiV1UserGetRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<UserResponseDataPageResult>> {
        const queryParameters: any = {};

        if (requestParameters.firstName !== undefined) {
            queryParameters['FirstName'] = requestParameters.firstName;
        }

        if (requestParameters.lastName !== undefined) {
            queryParameters['LastName'] = requestParameters.lastName;
        }

        if (requestParameters.email !== undefined) {
            queryParameters['Email'] = requestParameters.email;
        }

        if (requestParameters.careFirstMemberId !== undefined) {
            queryParameters['CareFirstMemberId'] = requestParameters.careFirstMemberId;
        }

        if (requestParameters.userObjectId !== undefined) {
            queryParameters['UserObjectId'] = requestParameters.userObjectId;
        }

        if (requestParameters.id !== undefined) {
            queryParameters['Id'] = requestParameters.id;
        }

        if (requestParameters.phoneNumber !== undefined) {
            queryParameters['PhoneNumber'] = requestParameters.phoneNumber;
        }

        if (requestParameters.sorterKey !== undefined) {
            queryParameters['Sorter.Key'] = requestParameters.sorterKey;
        }

        if (requestParameters.sorterSortType !== undefined) {
            queryParameters['Sorter.SortType'] = requestParameters.sorterSortType;
        }

        if (requestParameters.pageSize !== undefined) {
            queryParameters['PageSize'] = requestParameters.pageSize;
        }

        if (requestParameters.pageNumber !== undefined) {
            queryParameters['PageNumber'] = requestParameters.pageNumber;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", []);
        }

        const response = await this.request({
            path: `/api/v1/user`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => UserResponseDataPageResultFromJSON(jsonValue));
    }

    /**
     */
    async apiV1UserGet(requestParameters: ApiV1UserGetRequest, initOverrides?: RequestInit): Promise<UserResponseDataPageResult> {
        const response = await this.apiV1UserGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async apiV1UserIdGetRaw(requestParameters: ApiV1UserIdGetRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<UserResponseResult>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling apiV1UserIdGet.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", []);
        }

        const response = await this.request({
            path: `/api/v1/user/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => UserResponseResultFromJSON(jsonValue));
    }

    /**
     */
    async apiV1UserIdGet(requestParameters: ApiV1UserIdGetRequest, initOverrides?: RequestInit): Promise<UserResponseResult> {
        const response = await this.apiV1UserIdGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async apiV1UserIdWipePatchRaw(requestParameters: ApiV1UserIdWipePatchRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling apiV1UserIdWipePatch.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", []);
        }

        const response = await this.request({
            path: `/api/v1/user/{id}/wipe`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async apiV1UserIdWipePatch(requestParameters: ApiV1UserIdWipePatchRequest, initOverrides?: RequestInit): Promise<void> {
        await this.apiV1UserIdWipePatchRaw(requestParameters, initOverrides);
    }

    /**
     */
    async apiV1UserNotesGetRaw(requestParameters: ApiV1UserNotesGetRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<UserCsSwatNoteResponseIEnumerableResult>> {
        const queryParameters: any = {};

        if (requestParameters.userId !== undefined) {
            queryParameters['UserId'] = requestParameters.userId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", []);
        }

        const response = await this.request({
            path: `/api/v1/user/notes`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => UserCsSwatNoteResponseIEnumerableResultFromJSON(jsonValue));
    }

    /**
     */
    async apiV1UserNotesGet(requestParameters: ApiV1UserNotesGetRequest, initOverrides?: RequestInit): Promise<UserCsSwatNoteResponseIEnumerableResult> {
        const response = await this.apiV1UserNotesGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async apiV1UserNotesIdDeleteRaw(requestParameters: ApiV1UserNotesIdDeleteRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling apiV1UserNotesIdDelete.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", []);
        }

        const response = await this.request({
            path: `/api/v1/user/notes/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async apiV1UserNotesIdDelete(requestParameters: ApiV1UserNotesIdDeleteRequest, initOverrides?: RequestInit): Promise<void> {
        await this.apiV1UserNotesIdDeleteRaw(requestParameters, initOverrides);
    }

    /**
     */
    async apiV1UserNotesIdPatchRaw(requestParameters: ApiV1UserNotesIdPatchRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling apiV1UserNotesIdPatch.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", []);
        }

        const response = await this.request({
            path: `/api/v1/user/notes/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: UpdateUserNoteRequestToJSON(requestParameters.updateUserNoteRequest),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async apiV1UserNotesIdPatch(requestParameters: ApiV1UserNotesIdPatchRequest, initOverrides?: RequestInit): Promise<void> {
        await this.apiV1UserNotesIdPatchRaw(requestParameters, initOverrides);
    }

    /**
     */
    async apiV1UserNotesPostRaw(requestParameters: ApiV1UserNotesPostRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", []);
        }

        const response = await this.request({
            path: `/api/v1/user/notes`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CreateUserCsSwatNoteCommandToJSON(requestParameters.createUserCsSwatNoteCommand),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async apiV1UserNotesPost(requestParameters: ApiV1UserNotesPostRequest, initOverrides?: RequestInit): Promise<void> {
        await this.apiV1UserNotesPostRaw(requestParameters, initOverrides);
    }

}
