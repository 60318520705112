import React from 'react';
import { toast, Toaster, ToastBar } from 'react-hot-toast';

export const StyledToaster = () => {
    return (
        <Toaster
            position="top-center"
            reverseOrder={false}
            gutter={8}
            toastOptions={{
                duration: 3000,
                style: {
                    color: '#ffffff',
                    fontSize: '1.6rem',
                    lineHeight: '2.4rem',
                    fontFamily: 'Work-Sans-Medium',
                    backgroundColor: '#46145b',
                },
                success: {
                    style: {
                        backgroundColor: '#00cb00',
                    },
                },
                error: {
                    style: {
                        backgroundColor: '#E02020',
                    },
                },
            }}
        >
            {(t) => (
                <button onClick={() => t.type !== 'loading' && toast.dismiss(t.id)}>
                    <ToastBar toast={t}>
                        {({ icon, message }) => (
                            <>
                                {icon}
                                {message}
                            </>
                        )}
                    </ToastBar>
                </button>
            )}
        </Toaster>
    );
};
