import React from 'react';
import { useFeatureFlagsBackend } from 'api/swr/hooks/feature-flags/useFeatureFlagsBackend';
import { FeatureFlagsList } from 'views/feature-flags-navigator/components/feature-flags-list';
import { FEATURE_FLAGS } from 'views/feature-flags-navigator/constants';

export const FeatureFlagsBackend = () => {
    const { data, isLoading } = useFeatureFlagsBackend();
    return (
        <FeatureFlagsList
            type={FEATURE_FLAGS.BACKEND}
            isLoading={isLoading}
            data={data?.value || []}
        />
    );
};
