import React from 'react';
import { useTranslation } from 'react-i18next';
import { CreateEditNote } from 'views/user-navigator/user-details-navigator/user-notes/components/create-edit-note';
import { BUTTON_VARIANTS, StyledButton } from 'components/styled-button';
import { useState } from 'react';
import styled from 'styled-components';
import { StyledText } from 'components/styled-text';
import { ReactComponent as PlusIcon } from 'assets/svg/ico_plus.svg';
import { ReactComponent as LoadingSpinner } from 'assets/svg/ico_loading_spinner.svg';
import { useUserNotes } from 'api/swr/hooks/users/useUserNotes';
import { useParams } from 'react-router-dom';

const SRow = styled.div`
    display: flex;
    justify-content: flex-end;
    align-items: flex-start;
    margin-bottom: 2rem;
`;

const SUl = styled.ul`
    list-style-type: none;
`;

const SCenter = styled.div`
    display: flex;
    justify-content: center;
    margin-top: 3rem;
`;

const SAddFirstNoteButton = styled.button`
    justify-content: center;
    align-items: center;
    display: flex;
    height: 15rem;
    width: 100%;
    cursor: pointer;
    border-radius: 0.5rem;
    background-color: ${({ theme }) => theme.COLORS.TAN_TWO};
    margin-bottom: 2rem;
`;

const SPlusIcon = styled(PlusIcon)`
    margin-right: 1rem;
`;

export const UserNotes = () => {
    const { t } = useTranslation();
    const params = useParams();
    const userId = Number(params.id);

    const [editedId, setEditedId] = useState<string | undefined>();
    const { data, isLoading } = useUserNotes({ userId });
    const userNotes = data?.value || [];

    const isCreateMode = editedId === '';

    return (
        <>
            <SRow>
                <StyledButton variant={BUTTON_VARIANTS.TRANSPARENT} onClick={() => setEditedId('')}>
                    {t('userNotes.addNewNote')}
                </StyledButton>
            </SRow>
            {isCreateMode && (
                <CreateEditNote as="div" userId={userId} id={''} setEditedId={setEditedId} />
            )}
            {isLoading ? (
                <SCenter>
                    <LoadingSpinner />
                </SCenter>
            ) : userNotes.length ? (
                <SUl>
                    {userNotes.map((item) => {
                        const { id, note, updatedBy, updatedUtcDate } = item;
                        return (
                            <CreateEditNote
                                userId={userId}
                                key={id}
                                id={id}
                                note={note}
                                updatedUtcDate={updatedUtcDate}
                                updatedBy={updatedBy}
                                editedId={editedId}
                                setEditedId={setEditedId}
                            />
                        );
                    })}
                </SUl>
            ) : (
                !isCreateMode && (
                    <SAddFirstNoteButton onClick={() => setEditedId('')}>
                        <SPlusIcon />
                        <StyledText>{t('userNotes.addFirstNote')}</StyledText>
                    </SAddFirstNoteButton>
                )
            )}
        </>
    );
};
