import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { StyledText } from 'components/styled-text';
import { ReactComponent as LoadingSpinner } from 'assets/svg/ico_loading_spinner.svg';
import { useParams } from 'react-router-dom';
import { BillItem } from 'views/user-navigator/user-details-navigator/user-billings/components/bill-item';
import { useSimpleBills } from 'api/swr/hooks/simple-bills/useSimpleBills';
import { BUTTON_VARIANTS, StyledButton } from 'components/styled-button';
import { CreateBillForm } from 'views/user-navigator/user-details-navigator/user-billings/components/create-bill-form';
import { useAccount } from 'api/auth';
import { isAccessibleForUserRoles, ROLES } from 'helpers/isAccessibleForUserRoles';

const SRow = styled.div`
    display: flex;
    justify-content: flex-end;
    align-items: flex-start;
    margin-bottom: 2rem;
`;

const SUl = styled.ul`
    list-style-type: none;
`;

const SCenter = styled.div`
    display: flex;
    justify-content: center;
    margin-top: 3rem;
`;

const NoDataContainer = styled.div`
    justify-content: center;
    align-items: center;
    display: flex;
    height: 15rem;
    width: 100%;
    border-radius: 0.5rem;
    background-color: ${({ theme }) => theme.COLORS.TAN_TWO};
    margin-bottom: 2rem;
`;

export const UserBillings = () => {
    const { t } = useTranslation();
    const [isVisibleCreateBill, setIsVisibleCreateBill] = useState<boolean>(false);
    const params = useParams();
    const userId = Number(params.id);

    const account = useAccount();
    const userRoles = (account?.idTokenClaims as { roles: ROLES[] }).roles;

    const { data, isLoading } = useSimpleBills({ userId });
    const userSimpleBillsData = data?.value || [];

    return (
        <>
            {isAccessibleForUserRoles({ userRoles, rolesWithAccess: [ROLES.ADMIN] }) && (
                <SRow>
                    <StyledButton
                        variant={BUTTON_VARIANTS.TRANSPARENT}
                        onClick={() => setIsVisibleCreateBill(!isVisibleCreateBill)}
                    >
                        {isVisibleCreateBill ? t('common.cancel') : t('userBillings.addNewBill')}
                    </StyledButton>
                </SRow>
            )}
            {isVisibleCreateBill && (
                <CreateBillForm setIsVisibleCreateBill={setIsVisibleCreateBill} userId={userId} />
            )}
            {isLoading && !isVisibleCreateBill ? (
                <SCenter>
                    <LoadingSpinner />
                </SCenter>
            ) : userSimpleBillsData.length ? (
                <SUl>
                    {userSimpleBillsData.map((item) => (
                        <BillItem key={item.id} {...item} />
                    ))}
                </SUl>
            ) : (
                <NoDataContainer>
                    <StyledText>{t('userBillings.noBillings')}</StyledText>
                </NoDataContainer>
            )}
        </>
    );
};
