import React from 'react';
import { useFeatureFlagsApp } from 'api/swr/hooks/feature-flags/useFeatureFlagsApp';
import { FeatureFlagsList } from 'views/feature-flags-navigator/components/feature-flags-list';
import { FEATURE_FLAGS } from 'views/feature-flags-navigator/constants';

export const FeatureFlagsApp = () => {
    const { data, isLoading } = useFeatureFlagsApp();
    return (
        <FeatureFlagsList type={FEATURE_FLAGS.APP} isLoading={isLoading} data={data?.value || []} />
    );
};
