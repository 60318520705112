import React from 'react';
import { useParams } from 'react-router-dom';
import { getUserDetailsNavigationLinks } from 'views/user-navigator/user-details-navigator/components/user-details-navbar/constants';
import { useUser } from 'api/swr/hooks/users/useUser';
import { Navbar } from 'components/navbar';

export const UserDetailsNavbar = () => {
    const params = useParams();
    const userId = Number(params.id);

    const { data } = useUser({ id: userId });
    const { firstName, lastName, preferredFirstName } = data?.value || {};

    return (
        <Navbar
            navigationLinks={getUserDetailsNavigationLinks}
            title={`${preferredFirstName ? preferredFirstName : firstName} ${lastName}`}
        />
    );
};
