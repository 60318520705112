import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';

export enum FORM_FIELDS {
    NOTE = 'note',
}
export type Form = {
    [FORM_FIELDS.NOTE]: string;
};

export const validationSchema = yupResolver(
    yup.object().shape({
        [FORM_FIELDS.NOTE]: yup.string().max(4092).trim().required('errors.fieldIsRequired'),
    }),
);
