import useSWR, { SWRResponse } from 'swr';
import { useAuthorized } from 'api';
import { ApiV1UserGetRequest, UserApi } from 'generated';
import { UserResponseDataPageResult } from 'generated/models/UserResponseDataPageResult';
import { getUniqueKey } from 'api/swr/helpers/getUniqueKey';

type Response = SWRResponse<UserResponseDataPageResult> & {
    isLoading: boolean;
};

export const useUsers = (body: ApiV1UserGetRequest): Response => {
    const api = useAuthorized(UserApi);
    const key = getUniqueKey({ key: 'apiV1UserGet', body });
    const { data, error, ...rest } = useSWR(key, () => api.apiV1UserGet(body));
    const isLoading = data === undefined && !error && !!key;

    return { data, error, isLoading, ...rest };
};
