import useSWR, { SWRResponse } from 'swr';
import { useAuthorized } from 'api';
import {
    ApiV1UserDeviceGetRequest,
    UserDeviceApi,
    UserDeviceWithFirebaseDeviceIdDtoIEnumerableResult,
} from 'generated';
import { getUniqueKey } from 'api/swr/helpers/getUniqueKey';

type Response = SWRResponse<UserDeviceWithFirebaseDeviceIdDtoIEnumerableResult> & {
    isLoading: boolean;
};

export const useUserDevices = (body: ApiV1UserDeviceGetRequest): Response => {
    const api = useAuthorized(UserDeviceApi);
    const key = getUniqueKey({ key: 'apiV1UserDeviceGet', body });
    const { data, error, ...rest } = useSWR(key, () => api.apiV1UserDeviceGet(body));
    const isLoading = data === undefined && !error && !!key;

    return { data, error, isLoading, ...rest };
};
