import { css } from 'styled-components';

export const defaultTextStyles = css`
    color: ${({ theme }) => theme.COLORS.BLACK};
    font-size: ${({ theme }) => theme.FONT.SIZE.REGULAR};
    font-weight: ${({ theme }) => theme.FONT.WEIGHT.REGULAR};
    line-height: 2.4rem;
    font-family: ${({ theme }) => theme.FONT.FAMILY.WORK_SANS};
    text-align: left;
    overflow-wrap: break-word;
`;

export const defaultInputStyles = css<{ hasError: boolean }>`
    font-size: ${({ theme }) => theme.FONT.SIZE.REGULAR};
    font-family: ${({ theme }) => theme.FONT.FAMILY.WORK_SANS};
    line-height: 2.4rem;
    border: 1px solid ${({ theme }) => theme.COLORS.GRAY};
    border-radius: 4px;
    padding: 1rem;
    width: 100%;
    &:focus {
        outline-color: ${({ theme }) => theme.COLORS.BLACK};
    }
    ${({ hasError, theme }) =>
        hasError &&
        css`
            &:focus {
                outline-color: ${theme.COLORS.ERROR_RED};
            }
            border-color: ${theme.COLORS.ERROR_RED};
        `};
`;

export const visuallyHiddenStyles = css`
    clip: rect(0 0 0 0);
    clip-path: inset(50%);
    height: 1px;
    overflow: hidden;
    position: absolute;
    white-space: nowrap;
    width: 1px;
`;

export const errorTextStyles = css`
    font-size: ${({ theme }) => theme.FONT.SIZE.SMALL};
    color: ${({ theme }) => theme.COLORS.ERROR_RED};
    margin-top: 0.2rem;
`;
