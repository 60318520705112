/* tslint:disable */
/* eslint-disable */
/**
 * Administration API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface UpdateEligibilityOverrideCommand
 */
export interface UpdateEligibilityOverrideCommand {
    /**
     * 
     * @type {string}
     * @memberof UpdateEligibilityOverrideCommand
     */
    memberIdEligibilityResult?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof UpdateEligibilityOverrideCommand
     */
    active?: boolean | null;
    /**
     * 
     * @type {string}
     * @memberof UpdateEligibilityOverrideCommand
     */
    comments?: string | null;
    /**
     * 
     * @type {number}
     * @memberof UpdateEligibilityOverrideCommand
     */
    eligibilityOverrideId: number;
}

export function UpdateEligibilityOverrideCommandFromJSON(json: any): UpdateEligibilityOverrideCommand {
    return UpdateEligibilityOverrideCommandFromJSONTyped(json, false);
}

export function UpdateEligibilityOverrideCommandFromJSONTyped(json: any, ignoreDiscriminator: boolean): UpdateEligibilityOverrideCommand {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'memberIdEligibilityResult': !exists(json, 'memberIdEligibilityResult') ? undefined : json['memberIdEligibilityResult'],
        'active': !exists(json, 'active') ? undefined : json['active'],
        'comments': !exists(json, 'comments') ? undefined : json['comments'],
        'eligibilityOverrideId': json['eligibilityOverrideId'],
    };
}

export function UpdateEligibilityOverrideCommandToJSON(value?: UpdateEligibilityOverrideCommand | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'memberIdEligibilityResult': value.memberIdEligibilityResult,
        'active': value.active,
        'comments': value.comments,
        'eligibilityOverrideId': value.eligibilityOverrideId,
    };
}

