import { RowData } from 'components/table/types';
import { Column } from 'react-table';

export const getColumnNames = (t: (key: string) => string): Column<RowData>[] => [
    {
        Header: t('userSearch.firstName'),
        accessor: 'firstName',
    },
    {
        Header: t('userSearch.lastName'),
        accessor: 'lastName',
    },
    {
        Header: t('userSearch.careFirstMemberId'),
        accessor: 'careFirstMemberId',
    },
    {
        Header: t('userSearch.email'),
        accessor: 'email',
    },
];
