/* tslint:disable */
/* eslint-disable */
/**
 * Administration API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    UserActivityLogDtoDataPage,
    UserActivityLogDtoDataPageFromJSON,
    UserActivityLogDtoDataPageFromJSONTyped,
    UserActivityLogDtoDataPageToJSON,
} from './';

/**
 * 
 * @export
 * @interface UserActivityLogDtoDataPageResult
 */
export interface UserActivityLogDtoDataPageResult {
    /**
     * 
     * @type {number}
     * @memberof UserActivityLogDtoDataPageResult
     */
    readonly statusCode: number;
    /**
     * 
     * @type {string}
     * @memberof UserActivityLogDtoDataPageResult
     */
    errorDescription?: string | null;
    /**
     * 
     * @type {UserActivityLogDtoDataPage}
     * @memberof UserActivityLogDtoDataPageResult
     */
    value?: UserActivityLogDtoDataPage;
}

export function UserActivityLogDtoDataPageResultFromJSON(json: any): UserActivityLogDtoDataPageResult {
    return UserActivityLogDtoDataPageResultFromJSONTyped(json, false);
}

export function UserActivityLogDtoDataPageResultFromJSONTyped(json: any, ignoreDiscriminator: boolean): UserActivityLogDtoDataPageResult {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'statusCode': json['statusCode'],
        'errorDescription': !exists(json, 'errorDescription') ? undefined : json['errorDescription'],
        'value': !exists(json, 'value') ? undefined : UserActivityLogDtoDataPageFromJSON(json['value']),
    };
}

export function UserActivityLogDtoDataPageResultToJSON(value?: UserActivityLogDtoDataPageResult | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'errorDescription': value.errorDescription,
        'value': UserActivityLogDtoDataPageToJSON(value.value),
    };
}

