import React, { ElementType, useEffect, useState } from 'react';
import styled, { css } from 'styled-components';
import { StyledText } from 'components/styled-text';
import { useForm } from 'react-hook-form';
import {
    Form,
    FORM_FIELDS,
    validationSchema,
} from 'views/feature-flags-navigator/components/create-edit-flag/validators';
import { StyledButton } from 'components/styled-button';
import { useTranslation } from 'react-i18next';
import { StyledInput } from 'components/inputs/styled-input';
import { Checkbox } from 'components/inputs/checkbox';
import { FEATURE_FLAGS } from 'views/feature-flags-navigator/constants';
import toast from 'react-hot-toast';
import { FeatureFlagResponse, FeatureFlagsApi } from 'generated';
import { useAuthorized } from 'api';
import { useFeatureFlagsApp } from 'api/swr/hooks/feature-flags/useFeatureFlagsApp';
import { useFeatureFlagsBackend } from 'api/swr/hooks/feature-flags/useFeatureFlagsBackend';

const SContainer = styled.li`
    border-radius: 0.5rem;
    background-color: ${({ theme }) => theme.COLORS.WHITE};
    box-shadow: 0px 6px 11px -7px rgba(199, 198, 195, 1);
    margin-bottom: 2rem;
`;

const SSubContainer = styled.div`
    display: grid;
    grid-template-columns: 30% 30% 30%;
    grid-gap: 5%;
    margin-bottom: 2rem;
    ${({ theme }) => theme.MEDIA.MAX_1024`
        grid-template-columns: 48% 48%;
        grid-gap: 4%;
    `};
    ${({ theme }) => theme.MEDIA.MAX_768`
        grid-template-columns: 100%;
        grid-gap: 0%;
    `};
`;

const SRow = styled.div`
    display: flex;
    justify-content: space-between;
`;

const SSubRow = styled.div`
    display: flex;
    flex-wrap: wrap;
    word-break: break-all;
    margin-right: 2rem;
`;

const SButton = styled.button`
    padding: 2rem;
    width: 100%;
`;

const SButtons = styled.div`
    display: flex;
    justify-content: flex-end;
`;

const buttonStyle = css`
    margin-left: 2rem;
    width: 15rem;
`;

const SCancelButton = styled(StyledButton)`
    ${buttonStyle}
    color: ${({ theme }) => theme.COLORS.BLACK};
    background-color: ${({ theme }) => theme.COLORS.GRAY};
`;

const SSaveButton = styled(StyledButton)`
    ${buttonStyle}
`;

const SFlagName = styled(StyledText)`
    font-family: ${({ theme }) => theme.FONT.FAMILY.WORK_SANS_MEDIUM};
    margin-right: 2rem;
`;

const SEnabledText = styled(StyledText)`
    color: ${({ theme }) => theme.COLORS.SUCCESS_GREEN};
`;

const SDisabledText = styled(StyledText)`
    color: ${({ theme }) => theme.COLORS.ERROR_RED};
`;

type Props = {
    type: FEATURE_FLAGS;
    setEditedName: (name: string | undefined | null) => void;
    editedName?: string | null;
    as?: ElementType;
} & Pick<Partial<FeatureFlagResponse>, 'name' | 'extraData' | 'isEnabled'>;

export const CreateEditFlag = ({
    type,
    setEditedName,
    editedName,
    as,
    name,
    extraData,
    isEnabled,
}: Props) => {
    const [isSubmitLoading, setIsSubmitLoading] = useState(false);
    const { t } = useTranslation();
    const api = useAuthorized(FeatureFlagsApi);
    const { mutate: mutateFeatureFlagsApp } = useFeatureFlagsApp();
    const { mutate: mutateFeatureFlagsBackend } = useFeatureFlagsBackend();

    const { formState, control, handleSubmit, reset } = useForm<Form>({
        resolver: validationSchema,
    });
    const isCreateMode = name === '';
    const isEditMode = editedName === name;

    const isForm = isCreateMode || isEditMode;

    useEffect(() => {
        reset({
            [FORM_FIELDS.NAME]: name || '',
            [FORM_FIELDS.EXTRA_DATA]: extraData || '',
            [FORM_FIELDS.IS_ENABLED]: isEnabled || false,
        });
    }, [name, extraData, isEnabled]);

    const onCancel = () => {
        reset();
        setEditedName(undefined);
    };

    const onSubmit = async (data: Form) => {
        try {
            setIsSubmitLoading(true);
            if (type === FEATURE_FLAGS.APP) {
                await api.apiV1FeatureFlagsAppPut({
                    updateFeatureFlagsAppCommand: {
                        featureFlags: [data],
                    },
                });
                await mutateFeatureFlagsApp();
            } else {
                await api.apiV1FeatureFlagsBackendPut({
                    updateFeatureFlagsBackendCommand: {
                        featureFlags: [data],
                    },
                });
                await mutateFeatureFlagsBackend();
            }
            setEditedName(undefined);
            toast.success(t('featureFlags.successfullySaved'));
        } catch (error) {
            toast.error(t('errors.errorAlert'));
        } finally {
            setIsSubmitLoading(false);
        }
    };

    return (
        <SContainer as={as}>
            <SButton as={isForm ? 'form' : 'button'} onClick={() => !isForm && setEditedName(name)}>
                {isEditMode || isCreateMode ? (
                    <>
                        <SSubContainer>
                            <StyledInput
                                disabled={isEditMode}
                                label={t('featureFlags.flagName')}
                                name={FORM_FIELDS.NAME}
                                control={control}
                                error={formState.errors.name}
                            />
                            <StyledInput
                                label={t('featureFlags.extraData')}
                                name={FORM_FIELDS.EXTRA_DATA}
                                control={control}
                                error={formState.errors.extraData}
                            />
                            <Checkbox
                                label={t('featureFlags.isEnabled')}
                                name={FORM_FIELDS.IS_ENABLED}
                                control={control}
                                error={formState.errors.isEnabled}
                            />
                        </SSubContainer>
                        <SButtons>
                            <SSaveButton
                                isLoading={isSubmitLoading}
                                onClick={handleSubmit(onSubmit)}
                                disabled={!isCreateMode && !formState.isDirty}
                            >
                                {t('common.save')}
                            </SSaveButton>
                            <SCancelButton onClick={onCancel}>{t('common.cancel')}</SCancelButton>
                        </SButtons>
                    </>
                ) : (
                    <SRow>
                        <SSubRow>
                            <SFlagName>{name}</SFlagName>
                            <StyledText>{extraData}</StyledText>
                        </SSubRow>
                        {isEnabled ? (
                            <SEnabledText>{t('featureFlags.enabled')}</SEnabledText>
                        ) : (
                            <SDisabledText>{t('featureFlags.disabled')}</SDisabledText>
                        )}
                    </SRow>
                )}
            </SButton>
        </SContainer>
    );
};
