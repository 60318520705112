import React, { ElementType, useEffect, useState } from 'react';
import styled, { css } from 'styled-components';
import { StyledText } from 'components/styled-text';
import { useForm } from 'react-hook-form';
import {
    Form,
    FORM_FIELDS,
    validationSchema,
} from 'views/user-navigator/user-details-navigator/user-notes/components/create-edit-note/validators';
import { StyledButton } from 'components/styled-button';
import { useTranslation } from 'react-i18next';
import toast from 'react-hot-toast';
import { UserApi, UserCsSwatNoteResponse } from 'generated';
import { useAuthorized } from 'api';
import { useUserNotes } from 'api/swr/hooks/users/useUserNotes';
import dayjs from 'dayjs';
import { Textarea } from 'components/inputs/textarea';

const SContainer = styled.li`
    border-radius: 0.5rem;
    background-color: ${({ theme }) => theme.COLORS.WHITE};
    box-shadow: 0px 6px 11px -7px rgba(199, 198, 195, 1);
    margin-bottom: 2rem;
`;

const SButton = styled.button`
    padding: 2rem;
    width: 100%;
`;

const SDateText = styled(StyledText)`
    color: ${({ theme }) => theme.COLORS.TAN_THREE};
    margin-bottom: 1rem;
`;

const SButtons = styled.div`
    display: flex;
    justify-content: flex-end;
`;

const buttonStyle = css`
    margin-left: 2rem;
    width: 15rem;
`;

const SCancelButton = styled(StyledButton)`
    ${buttonStyle}
    color: ${({ theme }) => theme.COLORS.BLACK};
    background-color: ${({ theme }) => theme.COLORS.GRAY};
`;
const SDeleteButton = styled(StyledButton)`
    ${buttonStyle}
    background-color: ${({ theme }) => theme.COLORS.ERROR_RED};
`;

const SSaveButton = styled(StyledButton)`
    ${buttonStyle}
`;

type Props = {
    setEditedId: (id: string | undefined) => void;
    editedId?: string;
    as?: ElementType;
    userId: number;
} & Pick<UserCsSwatNoteResponse, 'id' | 'note' | 'updatedUtcDate' | 'updatedBy'>;

export const CreateEditNote = ({
    userId,
    id,
    note,
    updatedUtcDate,
    updatedBy,
    setEditedId,
    editedId,
    as,
}: Props) => {
    const [isSubmitLoading, setIsSubmitLoading] = useState(false);
    const [isDeleteLoading, setIsDeleteLoading] = useState(false);
    const { mutate } = useUserNotes({ userId });

    const { t } = useTranslation();

    const { formState, control, handleSubmit, reset } = useForm<Form>({
        resolver: validationSchema,
    });

    useEffect(() => {
        reset({
            [FORM_FIELDS.NOTE]: note || '',
        });
    }, [note]);

    const api = useAuthorized(UserApi);

    const isCreateMode = id === '';
    const isEditMode = editedId === id;
    const isForm = isCreateMode || isEditMode;

    const onCancel = () => {
        reset();
        setEditedId(undefined);
    };

    const onSubmit = async (data: Form) => {
        try {
            setIsSubmitLoading(true);
            if (isCreateMode) {
                await api.apiV1UserNotesPost({
                    createUserCsSwatNoteCommand: {
                        userId,
                        note: data.note,
                    },
                });
            } else {
                await api.apiV1UserNotesIdPatch({
                    id,
                    updateUserNoteRequest: {
                        note: data.note,
                    },
                });
            }
            await mutate();
            setEditedId(undefined);
            toast.success(t('userNotes.successfullySaved'));
        } catch (error) {
            toast.error(t('errors.errorAlert'));
        } finally {
            setIsSubmitLoading(false);
        }
    };

    const onDelete = async () => {
        try {
            setIsDeleteLoading(true);
            await api.apiV1UserNotesIdDelete({
                id,
            });
            await mutate();
            setEditedId(undefined);
            toast.success(t('userNotes.successfullyDeleted'));
        } catch (error) {
            toast.error(t('errors.errorAlert'));
        } finally {
            setIsDeleteLoading(false);
        }
    };

    const formattedUpdatedUtcDate = dayjs(updatedUtcDate).format('MM/DD/YYYY hh:mm');

    return (
        <SContainer as={as}>
            <SButton as={isForm ? 'form' : 'button'} onClick={() => !isForm && setEditedId(id)}>
                <SDateText>
                    {t('userNotes.updatedBy', {
                        name: updatedBy,
                        date: formattedUpdatedUtcDate,
                    })}
                </SDateText>
                {isEditMode || isCreateMode ? (
                    <>
                        <Textarea
                            rows={6}
                            label={t('userNotes.note')}
                            isLabelHidden={true}
                            name={FORM_FIELDS.NOTE}
                            control={control}
                            error={formState.errors.note}
                            placeholder={t('userNotes.notePlaceholder')}
                        />
                        <SButtons>
                            <SSaveButton
                                isLoading={isSubmitLoading}
                                onClick={handleSubmit(onSubmit)}
                                disabled={!isCreateMode && !formState.isDirty}
                            >
                                {t('common.save')}
                            </SSaveButton>
                            {!isCreateMode && (
                                <SDeleteButton isLoading={isDeleteLoading} onClick={onDelete}>
                                    {t('common.delete')}
                                </SDeleteButton>
                            )}
                            <SCancelButton onClick={onCancel}>{t('common.cancel')}</SCancelButton>
                        </SButtons>
                    </>
                ) : (
                    <StyledText>{note}</StyledText>
                )}
            </SButton>
        </SContainer>
    );
};
