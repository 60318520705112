import React, { MutableRefObject } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { Title } from 'components/title';
import { Table } from 'components/table';
import { getColumnNames } from 'views/eligibility-overrides-navigator/eligibility-overrides-search/components/eligibility-overrides-results/constants';
import { RowData } from 'components/table/types';
import { useEligibilityOverrides } from 'api/swr/hooks/eligibility-overrides/useEligibilityOverrides';
import { ApiV1EligibilityOverrideGetRequest } from 'generated';
import { SortingRule } from 'react-table';
import { getPageCount } from 'helpers/getPageCount';

type Props = {
    eligibilityOverridesSearchData: ApiV1EligibilityOverrideGetRequest;
    setPageNumber: (pageNumber: number) => void;
    setSortByList: (sortBy: SortingRule<RowData>[]) => void;
    resetTableState: MutableRefObject<boolean>;
};

export const EligibilityOverridesResults = ({
    eligibilityOverridesSearchData,
    setPageNumber,
    setSortByList,
    resetTableState,
}: Props) => {
    const { t } = useTranslation();
    const navigate = useNavigate();

    const editEligibilityOverride = (row: RowData) => {
        navigate(`${row.eligibilityOverrideId}`);
    };

    const userId = Number(eligibilityOverridesSearchData.userId) || undefined;

    const { data, isLoading } = useEligibilityOverrides({
        ...eligibilityOverridesSearchData,
        userId,
    });
    const { value } = data || {};

    const formattedData = value?.items?.map((item) => {
        return {
            ...item,
            active: t(`common.${item.active ? 'yes' : 'no'}`),
            updatedDate: item.updatedDate?.toLocaleDateString('en-US'),
        };
    });

    const pageCount = getPageCount(value?.totalCount, eligibilityOverridesSearchData.pageSize);

    return (
        <div>
            <Title>{t('eligibilityOverridesSearch.eligibilityOverridesResults')}</Title>
            <Table
                onClickAction={editEligibilityOverride}
                columns={getColumnNames(t)}
                data={formattedData || []}
                isLoading={isLoading}
                controlledPageCount={pageCount}
                setPageNumber={setPageNumber}
                resetTableState={resetTableState}
                setSortByList={setSortByList}
            />
        </div>
    );
};
