import queryString from 'query-string';

type Props = {
    key: string;
    body?: Record<string, any>;
};

export const getUniqueKey = ({ key, body }: Props) => {
    let uniqueKey: string | null = key;
    if (body) {
        const qs = queryString.stringify(body, { skipNull: true, skipEmptyString: true });
        uniqueKey = qs ? `${key}?${qs}` : null;
    }
    return uniqueKey;
};
