/* tslint:disable */
/* eslint-disable */
/**
 * Administration API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    EligibilityOverrideDto,
    EligibilityOverrideDtoFromJSON,
    EligibilityOverrideDtoFromJSONTyped,
    EligibilityOverrideDtoToJSON,
} from './';

/**
 * 
 * @export
 * @interface EligibilityOverrideDtoDataPage
 */
export interface EligibilityOverrideDtoDataPage {
    /**
     * 
     * @type {Array<EligibilityOverrideDto>}
     * @memberof EligibilityOverrideDtoDataPage
     */
    items?: Array<EligibilityOverrideDto> | null;
    /**
     * 
     * @type {number}
     * @memberof EligibilityOverrideDtoDataPage
     */
    totalCount: number;
}

export function EligibilityOverrideDtoDataPageFromJSON(json: any): EligibilityOverrideDtoDataPage {
    return EligibilityOverrideDtoDataPageFromJSONTyped(json, false);
}

export function EligibilityOverrideDtoDataPageFromJSONTyped(json: any, ignoreDiscriminator: boolean): EligibilityOverrideDtoDataPage {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'items': !exists(json, 'items') ? undefined : (json['items'] === null ? null : (json['items'] as Array<any>).map(EligibilityOverrideDtoFromJSON)),
        'totalCount': json['totalCount'],
    };
}

export function EligibilityOverrideDtoDataPageToJSON(value?: EligibilityOverrideDtoDataPage | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'items': value.items === undefined ? undefined : (value.items === null ? null : (value.items as Array<any>).map(EligibilityOverrideDtoToJSON)),
        'totalCount': value.totalCount,
    };
}

