import React, { MutableRefObject } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { Title } from 'components/title';
import { Table } from 'components/table';
import { getColumnNames } from 'views/user-navigator/user-search/components/user-results/constants';
import { RowData } from 'components/table/types';
import { useUsers } from 'api/swr/hooks/users/useUsers';
import { ApiV1UserGetRequest } from 'generated';
import { SortingRule } from 'react-table';
import { getPageCount } from 'helpers/getPageCount';

type Props = {
    userSearchData: ApiV1UserGetRequest;
    setPageNumber: (pageNumber: number) => void;
    setSortByList: (sortBy: SortingRule<RowData>[]) => void;
    resetTableState: MutableRefObject<boolean>;
};

export const UserResults = ({
    userSearchData,
    setPageNumber,
    setSortByList,
    resetTableState,
}: Props) => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const userDetails = (row: RowData) => {
        navigate(`${row.id}`);
    };
    const { data, isLoading } = useUsers(userSearchData);
    const { value } = data || {};
    const pageCount = getPageCount(value?.totalCount, userSearchData.pageSize);

    return (
        <div>
            <Title>{t('userSearch.userResults')}</Title>
            <Table
                actionLabel={t('userSearch.view')}
                onClickAction={userDetails}
                columns={getColumnNames(t)}
                data={value?.items || []}
                isLoading={isLoading}
                controlledPageCount={pageCount}
                setPageNumber={setPageNumber}
                setSortByList={setSortByList}
                resetTableState={resetTableState}
            />
        </div>
    );
};
