import React from 'react';
import styled from 'styled-components';
import { StyledText } from 'components/styled-text';
import { defaultTextStyles } from 'styles/styles';

const SLi = styled.li`
    margin-bottom: 1rem;
    padding-bottom: 1rem;
    border-bottom: 1px solid ${({ theme }) => theme.COLORS.GRAY};
`;

const SLabel = styled.h5`
    ${defaultTextStyles}
    font-family: ${({ theme }) => theme.FONT.FAMILY.WORK_SANS_MEDIUM};
`;

type Props = {
    label: string;
    value?: string | number | null;
    className?: string;
};

export const LabelWithValue = ({ label, value, className }: Props) => {
    return (
        <SLi className={className}>
            <SLabel>{label}</SLabel>
            <StyledText>{value ? value : 'N/A'}</StyledText>
        </SLi>
    );
};
