import React, { ReactNode } from 'react';
import { defaultTextStyles } from 'styles/styles';
import styled from 'styled-components';

const STitle = styled.h4`
    ${defaultTextStyles}
    font-family: ${({ theme }) => theme.FONT.FAMILY.WORK_SANS_MEDIUM};
    color: ${({ theme }) => theme.COLORS.PRIMARY};
    border-bottom: 1px solid ${({ theme }) => theme.COLORS.PRIMARY};
    padding-bottom: 0.5rem;
    margin-bottom: 2rem;
`;

type Props = {
    children: ReactNode;
    className?: string;
};

export const Subtitle = ({ children, className }: Props) => {
    return <STitle className={className}>{children}</STitle>;
};
