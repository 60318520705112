import React, { useEffect } from 'react';
import styled from 'styled-components';
import { useTable, usePagination, useRowSelect, useFlexLayout, useSortBy } from 'react-table';
import { TableProps } from 'components/table/types';
import { defaultTextStyles } from 'styles/styles';
import { useTranslation } from 'react-i18next';
import { Checkbox } from 'components/table/components/checkbox';
import { MassActionForm } from 'components/table/components/mass-action-form';
import { Pagination } from 'components/table/components/pagination';
import { PaginationContainer } from 'components/table/components/pagination-container';
import { TableHeader } from 'components/table/components/table-header';
import { TableBody } from 'components/table/components/table-body';

const SContainer = styled.div`
    overflow-x: auto;
`;

const STable = styled.table`
    table-layout: auto;
    width: 100%;
`;

const SActionButton = styled.button`
    ${defaultTextStyles}
    font-family: ${({ theme }) => theme.FONT.FAMILY.WORK_SANS};
`;

export enum EXTRA_COLUMNS {
    ACTION = 'action',
    SELECTION = 'selection',
}

export const Table = ({
    data,
    columns,
    onClickAction,
    isLoading,
    massActions,
    handleMassAction,
    actionLabel,
    setPageNumber,
    controlledPageCount,
    resetTableState,
    setSortByList,
}: TableProps) => {
    const { t } = useTranslation();

    const hasMassActions = !!(massActions && handleMassAction);
    const isDataAvailable = !!data.length;
    const hiddenColumns = [
        ...(hasMassActions ? [] : [EXTRA_COLUMNS.SELECTION]),
        ...(onClickAction ? [] : [EXTRA_COLUMNS.ACTION]),
    ];
    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        prepareRow,
        page,
        canPreviousPage,
        canNextPage,
        pageCount,
        gotoPage,
        nextPage,
        previousPage,
        setSortBy,
        selectedFlatRows,
        state: { pageIndex, pageSize, sortBy },
    } = useTable(
        {
            columns,
            data,
            initialState: {
                pageIndex: 0,
                hiddenColumns,
            },
            manualPagination: true,
            pageCount: controlledPageCount,
            autoResetPage: false,
            manualSortBy: true,
            disableSortBy: !setSortByList || !isDataAvailable,
        },
        useSortBy,
        usePagination,
        useRowSelect,
        useFlexLayout,
        (hooks) => {
            hooks.visibleColumns.push((columns) => {
                return [
                    {
                        id: EXTRA_COLUMNS.SELECTION,
                        width: 35,
                        Header: ({ getToggleAllPageRowsSelectedProps }) => (
                            <Checkbox {...getToggleAllPageRowsSelectedProps()} />
                        ),
                        Cell: ({ row }) => <Checkbox {...row.getToggleRowSelectedProps()} />,
                    },
                    ...columns,
                    {
                        id: EXTRA_COLUMNS.ACTION,
                        width: 55,
                        Header: () => t('table.action'),
                        Cell: ({ row }) => (
                            <SActionButton
                                onClick={() => onClickAction && onClickAction(row.original)}
                            >
                                {actionLabel ? actionLabel : t('common.edit')}
                            </SActionButton>
                        ),
                    },
                ];
            });
        },
    );

    useEffect(() => {
        setPageNumber(pageIndex);
        setSortByList(sortBy);
    }, [pageIndex, sortBy]);

    useEffect(() => {
        if (resetTableState && resetTableState.current) {
            gotoPage(0);
            setSortBy([]);
            resetTableState.current = false;
        }
    }, [data]);

    useEffect(() => {
        gotoPage(0);
    }, [sortBy]);

    return (
        <SContainer>
            <PaginationContainer
                isVisible={isDataAvailable || isLoading}
                component={
                    <Pagination
                        gotoPage={gotoPage}
                        previousPage={previousPage}
                        nextPage={nextPage}
                        canPreviousPage={canPreviousPage}
                        canNextPage={canNextPage}
                        pageIndex={pageIndex}
                        pageCount={pageCount}
                        pageSize={pageSize}
                    />
                }
            >
                {massActions && handleMassAction && (
                    <MassActionForm
                        massActions={massActions}
                        selectedRows={selectedFlatRows.map((row) => row.original)}
                        handleMassAction={handleMassAction}
                    />
                )}
                <STable {...getTableProps()}>
                    <TableHeader headerGroups={headerGroups} />
                    <TableBody
                        page={page}
                        getTableBodyProps={getTableBodyProps}
                        isLoading={isLoading}
                        isDataAvailable={isDataAvailable}
                        prepareRow={prepareRow}
                    />
                </STable>
            </PaginationContainer>
        </SContainer>
    );
};
