/* tslint:disable */
/* eslint-disable */
/**
 * Administration API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    SimpleBillDto,
    SimpleBillDtoFromJSON,
    SimpleBillDtoFromJSONTyped,
    SimpleBillDtoToJSON,
} from './';

/**
 * 
 * @export
 * @interface SimpleBillDtoIEnumerableResult
 */
export interface SimpleBillDtoIEnumerableResult {
    /**
     * 
     * @type {number}
     * @memberof SimpleBillDtoIEnumerableResult
     */
    readonly statusCode: number;
    /**
     * 
     * @type {string}
     * @memberof SimpleBillDtoIEnumerableResult
     */
    errorDescription?: string | null;
    /**
     * 
     * @type {Array<SimpleBillDto>}
     * @memberof SimpleBillDtoIEnumerableResult
     */
    value?: Array<SimpleBillDto> | null;
}

export function SimpleBillDtoIEnumerableResultFromJSON(json: any): SimpleBillDtoIEnumerableResult {
    return SimpleBillDtoIEnumerableResultFromJSONTyped(json, false);
}

export function SimpleBillDtoIEnumerableResultFromJSONTyped(json: any, ignoreDiscriminator: boolean): SimpleBillDtoIEnumerableResult {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'statusCode': json['statusCode'],
        'errorDescription': !exists(json, 'errorDescription') ? undefined : json['errorDescription'],
        'value': !exists(json, 'value') ? undefined : (json['value'] === null ? null : (json['value'] as Array<any>).map(SimpleBillDtoFromJSON)),
    };
}

export function SimpleBillDtoIEnumerableResultToJSON(value?: SimpleBillDtoIEnumerableResult | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'errorDescription': value.errorDescription,
        'value': value.value === undefined ? undefined : (value.value === null ? null : (value.value as Array<any>).map(SimpleBillDtoToJSON)),
    };
}

