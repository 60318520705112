import React, { ReactNode } from 'react';
import styled from 'styled-components';

const SContainer = styled.div`
    display: flex;
    flex-direction: column;
    margin: 0 auto;
    max-width: 144rem;
    width: 100%;
    padding: 2rem 2rem;
    background-color: ${({ theme }) => theme.COLORS.TAN};
    ${({ theme }) => theme.MEDIA.MAX_1024`
        width: 100%;
    `};
`;

type Props = {
    children: ReactNode;
};

export const StyledContainer = ({ children }: Props) => {
    return <SContainer>{children}</SContainer>;
};
