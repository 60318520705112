import React from 'react';
import { Control, FieldError, useController, Path } from 'react-hook-form';
import { resolveErrorMessage } from 'helpers/resolveErrorMessage';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { InputContainer } from 'components/inputs/input-container';

const SCheckbox = styled.input`
    display: block;
    width: 2.2rem;
    height: 2.2rem;
`;

export type Props<T> = {
    name: Path<T>;
    control: Control<T>;
    label: string;
    error?: FieldError;
} & Partial<Pick<HTMLInputElement, 'disabled'>>;

export const Checkbox = <T extends Record<string, unknown>>({
    name,
    control,
    label,
    error,
    disabled,
}: Props<T>) => {
    const { t } = useTranslation();

    const errorMessage = resolveErrorMessage({
        t,
        error,
        label,
    })?.replace(/\*/g, '');

    const { field } = useController({
        name,
        control,
    });
    const { value, onChange } = field;

    return (
        <InputContainer name={name} label={label} errorMessage={errorMessage}>
            <SCheckbox
                id={name}
                name={name}
                type="checkbox"
                checked={!!value}
                disabled={disabled}
                onChange={onChange}
            />
        </InputContainer>
    );
};
